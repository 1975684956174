import { ReactNode } from 'react'
import { TooltipContentProps } from '@radix-ui/react-tooltip'

import {
  Tooltip as ShadTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip'

interface TooltipProps extends TooltipContentProps {
  label: ReactNode
  children: ReactNode
}

export function Tooltip({ label, children, ...rest }: TooltipProps) {
  return (
    <TooltipProvider>
      <ShadTooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <span>{children}</span>
        </TooltipTrigger>
        {label && (
          <TooltipContent {...rest}>
            <p>{label}</p>
          </TooltipContent>
        )}
      </ShadTooltip>
    </TooltipProvider>
  )
}
