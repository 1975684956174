import { useMemo } from 'react'

import { ProjectKindStatusItem } from '@/lib/requests/project-kinds/types'
import { ProjectHistory } from '@/lib/requests/projects/get-project-by-id'

import { Divider } from './containers/divider'
import { Indicator } from './containers/indicator'
import { Text } from './containers/text'

export interface TimelineItemProps {
  item: ProjectHistory | null
  nextItem: ProjectHistory | null
  statusList: ProjectKindStatusItem[]
  currentStatus: { label: string }
  currentIndex: number
}

export function TimelineItem({
  item,
  nextItem,
  statusList,
  currentStatus,
  currentIndex,
}: TimelineItemProps) {
  const dividerDone = useMemo(() => {
    return !!item?.created_at && !!nextItem?.created_at
  }, [item?.created_at, nextItem?.created_at])

  const shouldBlink = useMemo(() => {
    return !!item?.created_at && !nextItem?.created_at
  }, [item?.created_at, nextItem?.created_at])

  return (
    <div className="flex flex-row items-start justify-start">
      <div className="flex flex-col">
        <Indicator isDone={!!item} />

        {statusList.length - 1 !== currentIndex && (
          <Divider isDone={dividerDone} shouldBlink={shouldBlink} />
        )}
      </div>

      <Text
        statusItem={currentStatus}
        username={item?.username}
        doneDate={item?.created_at}
      />
    </div>
  )
}
