import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { InputField } from '@/components/Form/Input/base'
import { SelectField } from '@/components/Form/select'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { UserOption } from '@/lib/requests/users/get-users.ts'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'

interface IFormSchema {
  done_deal: string
  title: string
  user_id: string
  code: string
}

const DEFAULT_VALUES = {
  done_deal: '',
  title: '',
  user_id: '',
  code: '',
}

interface ProjectsFiltersDrawerProps {
  users: UserOption[]
}

export default function ProjectsFiltersDrawer({
  users,
}: ProjectsFiltersDrawerProps) {
  const setFilters = useProjectStore((store) => store.actions.setFilters)
  const setPagination = useProjectStore((store) => store.actions.setPagination)
  const filters = useProjectStore((store) => store.state.filters)

  const {
    state: { isAdmin },
  } = useAppStore()
  const isOpened = useProjectStore((store) => store.state.modals.filters)
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const form = useForm<IFormSchema>({
    defaultValues: {
      done_deal: '',
      title: '',
      code: '',
      user_id: '',
    },
    values: {
      done_deal: filters.done_deal || '',
      code: filters.code || '',
      user_id: filters.user_id || '',
      title: filters.title || '',
    },
  })

  const handleSubmit = useCallback(
    (data: IFormSchema) => {
      setPagination({ page: 1 })
      setFilters({
        user_id: data.user_id,
        title: data.title,
        done_deal: data.done_deal ? String(data.done_deal) : '',
        code: data.code,
      })
    },
    [setFilters, setPagination],
  )

  const close = useCallback(() => {
    setModalStatus({ name: 'filters' }, false)
  }, [setModalStatus])

  return (
    <Sheet open={isOpened.status && isAdmin} onOpenChange={close}>
      <SheetContent>
        <div className="flex flex-col gap-4">
          <SheetHeader>
            <SheetTitle>Filtros</SheetTitle>
          </SheetHeader>

          <Form {...form}>
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <InputField<IFormSchema>
                name={'title'}
                label={'Título do projeto'}
                inputProps={{
                  placeholder: 'Título do projeto',
                }}
              />

              <InputField<IFormSchema>
                name={'code'}
                label={'Código do projeto'}
                inputProps={{
                  placeholder: 'Código do projeto',
                }}
              />

              <SelectField<IFormSchema>
                name={'user_id'}
                placeholder="Cliente"
                label={'Cliente'}
                options={[{ label: 'Todos', value: null }, ...users]}
                disabled={users.length === 0}
              />

              <SelectField<IFormSchema>
                name={'done_deal'}
                label={'Negócio fechado'}
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                  { label: 'Todos', value: null },
                ]}
                placeholder="Negócio fechado"
              />

              <div className="flex justify-end gap-2">
                <Button
                  variant={'secondary'}
                  type="button"
                  onClick={() => form.reset(DEFAULT_VALUES)}
                >
                  <span>Limpar</span>
                </Button>
                <Button type="submit">
                  <span>Buscar</span>
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}
