import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { Check, Edit2, Hourglass, Plus, Trash } from 'lucide-react'
import { toast } from 'sonner'

import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination'
import { Popover, PopoverTrigger } from '@/components/ui/popover'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { mountFileUrl } from '@/lib/mount-file-url'
import { paymentMethodOptions } from '@/lib/payment-method-options'
import { GET_PROJECT_FINANCIAL } from '@/lib/react-query/keys'
import { getProjectFinancialByProjectId } from '@/lib/requests/project_financials/get-by-project-id'
import {
  updateProject,
  UpdateProjectFinancialRequest,
} from '@/lib/requests/projects/update-project'
import { useAppStore } from '@/store/app.store.ts'
import { useProjectStore } from '@/store/project/store'
import { DeleteItemConfirmationDialog } from '@/pages/Projects/components/Form/components/delete-item-confirmation-dialog.tsx'
import { Button } from '@/components/ui/button.tsx'

interface ProjectFinancialModalProps {
  projectId: string
  readonly?: boolean
}

export function FinanceTabContent({
  projectId,
  readonly = false,
}: ProjectFinancialModalProps) {
  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const queryClient = useQueryClient()
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const [page, setPage] = useState(1)

  const { mutateAsync: handleDelete, isPending: isLoadingDelete } = useMutation(
    {
      mutationFn: ({ rowId }: { rowId: string }) => {
        return updateProject<UpdateProjectFinancialRequest>(projectId, {
          project_financials_attributes: [{ id: rowId, _destroy: true }],
        })
      },
      onSuccess: async () => {
        toast.success('Registro removido com sucesso')
        await queryClient.invalidateQueries({
          queryKey: [GET_PROJECT_FINANCIAL, projectId],
          exact: false,
        })
      },
    },
  )

  const { data, isLoading } = useQuery({
    queryKey: [GET_PROJECT_FINANCIAL, projectId, page],
    queryFn: () => {
      if (!projectId) return
      return getProjectFinancialByProjectId(projectId, { page })
    },
  })

  const getHumanizedPaymentMethod = useCallback((paymentMethod: string) => {
    return (
      paymentMethodOptions.find((item) => item.value === paymentMethod)
        ?.label || paymentMethod
    )
  }, [])

  const total = useMemo(() => {
    return (
      data?.data?.reduce((acc, curr) => {
        return acc + Number(curr.value)
      }, 0) || 0
    )
  }, [data])

  return (
    <>
      <div className={'w-full flex justify-end mt-5'}>
        {isAdmin && (
          <Button
            type={'button'}
            disabled={readonly}
            onClick={() =>
              setModalStatus(
                { name: 'financialForm', payload: { projectId } },
                true,
              )
            }
            className="flex space-x-2"
          >
            <Plus />
            <span>Novo Registro</span>
          </Button>
        )}
      </div>

      {!data?.data?.length ? (
        <div className="my-10 w-full">
          <span className="flex items-center justify-center text-xl">
            {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
          </span>
        </div>
      ) : (
        <Table className="w-full">
          <TableHeader>
            <TableRow>
              <TableHead>Data</TableHead>
              <TableHead>Valor</TableHead>
              <TableHead>Forma de Pgto.</TableHead>
              <TableHead>Comprovante</TableHead>
              <TableHead>Pago</TableHead>
              {isAdmin && <TableHead />}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.data?.map((d) => (
              <TableRow key={d.id}>
                <TableCell>{format(parseISO(d.date), 'dd/MM/yyyy')}</TableCell>
                <TableCell>
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(d.value)}
                </TableCell>
                <TableCell>
                  {getHumanizedPaymentMethod(d.payment_method)}
                </TableCell>
                <TableCell>
                  {d.receipt_url ? (
                    <button
                      onClick={() => {
                        if (!d.receipt_url) return
                        window.open(mountFileUrl(d.receipt_url), '_blank')
                      }}
                      rel="noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Ver comprovante
                    </button>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {d.paid ? <Check color="green" /> : <Hourglass />}
                </TableCell>
                {isAdmin && (
                  <TableCell className="flex gap-2">
                    <button
                      type="button"
                      disabled={readonly}
                      onClick={() => {
                        setModalStatus(
                          {
                            name: 'financialForm',
                            payload: {
                              projectId,
                              financialItemData: d,
                            },
                          },
                          true,
                        )
                      }}
                    >
                      <Edit2 size={16} />
                    </button>

                    <Popover>
                      <PopoverTrigger asChild>
                        <button type="button" disabled={readonly}>
                          <Trash size={16} color="red" />
                        </button>
                      </PopoverTrigger>
                      <DeleteItemConfirmationDialog
                        isLoading={isLoadingDelete}
                        onConfirm={async () =>
                          await handleDelete({ rowId: d.id })
                        }
                      />
                    </Popover>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className="bg-white text-black">
            <TableRow>
              <TableCell colSpan={1}>Total</TableCell>
              <TableCell className="text-left">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(total)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}

      {Number(data?.meta.total_pages || 0) > 1 && (
        <Pagination
          page={page}
          totalPages={data?.meta.total_pages || 0}
          setPage={setPage}
        />
      )}
    </>
  )
}
