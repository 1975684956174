import { api } from '@/lib/api'
import { PaginatedResponse } from '@/lib/requests/types.ts'

export interface User {
  id: string
  name: string
  email: string
  avatar_url: string | null
  active: boolean
  total_projects: number
}

export interface UserOption extends User {
  label: string
  value: string
}

export interface PaginationParams {
  page?: number
  perPage?: number
}

export interface GetUsersParams {
  paginationOptions: PaginationParams
}

export async function getUsers({ paginationOptions }: GetUsersParams) {
  const { page = 1, perPage = 50 } = paginationOptions

  const result = await api.get<PaginatedResponse<User>>('/users', {
    params: {
      page,
      per_page: perPage,
    },
  })

  return result.data
}
