import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Check, Edit2, Loader2, LucideMail, Plus, X } from 'lucide-react'

import { Pagination } from '@/components/pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { GET_USERS } from '@/lib/react-query/keys'
import { useAppStore } from '@/store/app.store'
import { getUsers } from '@/lib/requests/users/get-users.ts'
import { Button } from '@/components/ui/button.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import { useUserStore } from '@/store/user/store.ts'
import { CreateUpdateUserModal } from '@/pages/Users/CreateUpdateUserModal'
import { Popover, PopoverTrigger } from '@/components/ui/popover.tsx'
import { SendUserAccessConfirmationDialog } from '@/pages/Users/SendUserAccessConfirmationDialog'
import { sendUserAccess } from '@/lib/requests/users/send-access.ts'
import { toast } from 'sonner'

export function UsersPage() {
  const [searchParams] = useSearchParams()
  const setModalStatus = useUserStore((store) => store.actions.setModalStatus)
  const userFormModalIsOpen = useUserStore(
    (state) => state.state.modals.createUpdateUserForm.status,
  )

  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const page = useMemo(() => {
    return Number(searchParams.get('page') || 1)
  }, [searchParams])

  const { data, isLoading } = useQuery({
    queryKey: [GET_USERS, page],
    queryFn: () =>
      getUsers({
        paginationOptions: { page },
      }),
  })

  const {
    mutate: handleSendUserAcess,
    isPending: handleSendUserAcessIsPending,
  } = useMutation({
    mutationFn: ({ userId }: { userId: string; email: string }) => {
      return sendUserAccess(userId)
    },
    onSuccess: async (_, vars) => {
      toast.success(`Acesso enviado para ${vars.email}.`)
    },
  })

  const renderItems = useCallback(
    (element: JSX.Element) => {
      if (isLoading) {
        return (
          <div className="flex h-[100px] w-full items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        )
      }

      if (!data?.data.length || data.meta.total_count === 0) {
        return (
          <div className="mt-10 w-full">
            <span className="flex items-center justify-center text-2xl">
              Não há items para exibir
            </span>
          </div>
        )
      }

      return element
    },
    [isLoading, data],
  )

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <span className="my-5 block text-left text-2xl">Clientes</span>
        {isAdmin && (
          <div className="flex space-x-2">
            <Button
              onClick={() => {
                setModalStatus({ name: 'createUpdateUserForm' }, true)
              }}
              className="flex space-x-2"
            >
              <Plus />
              <span>Novo Cliente</span>
            </Button>
          </div>
        )}
      </div>

      <section className="w-full max-w-6xl">
        {renderItems(
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead>Nome</TableHead>
                <TableHead>E-mail</TableHead>
                <TableHead>Número de Projetos</TableHead>
                <TableHead>Ativo</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.data?.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.total_projects}</TableCell>
                    <TableCell>
                      {item.active ? (
                        <Check color={'green'} />
                      ) : (
                        <X color={'red'} />
                      )}
                    </TableCell>
                    <TableCell className="flex gap-2">
                      <Tooltip label={'Editar'}>
                        <button
                          type="button"
                          onClick={() => {
                            setModalStatus(
                              {
                                name: 'createUpdateUserForm',
                                payload: item,
                              },
                              true,
                            )
                          }}
                        >
                          <Edit2 size={16} />
                        </button>
                      </Tooltip>

                      <Tooltip label={'Enviar e-mail de acesso'}>
                        <Popover>
                          <PopoverTrigger asChild>
                            <button type="button">
                              <LucideMail size={16} />
                            </button>
                          </PopoverTrigger>
                          <SendUserAccessConfirmationDialog
                            user={{
                              name: item.name,
                              email: item.email,
                            }}
                            isLoading={handleSendUserAcessIsPending}
                            onConfirm={() =>
                              handleSendUserAcess({
                                userId: item.id,
                                email: item.email,
                              })
                            }
                          />
                        </Popover>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>,
        )}

        {Number(data?.meta.total_pages || 0) > 1 && (
          <Pagination page={page} totalPages={data?.meta.total_pages || 0} />
        )}
      </section>

      {userFormModalIsOpen && <CreateUpdateUserModal />}
    </>
  )
}
