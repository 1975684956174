import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import { z } from 'zod'

import LoginImg from '@/assets/login.png'
import { LoadingButton } from '@/components/Form/button'
import { InputField } from '@/components/Form/Input/base'
import { Form } from '@/components/ui/form'
import { api } from '@/lib/api'

interface IFormType {
  passwordConfirmation: string
  password: string
}

const schema = z
  .object({
    password: z
      .string({
        required_error: 'Campo obrigatório',
      })
      .min(6, { message: 'A senha deve ter no mínimo 6 caracteres' }),
    passwordConfirmation: z.string({
      required_error: 'Campo obrigatório',
    }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'As senhas não coincidem',
    path: ['passwordConfirmation'],
  })

export function NewPasswordPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const createPasswordMutation = useMutation({
    mutationFn: async (data: IFormType) => {
      const token = searchParams.get('token')
      if (!token) throw Error('Token não encontrado')

      await api.patch('/users/password', {
        token,
        password: data.password,
      })
    },
    onSuccess: () => {
      toast.success('Senha criada com sucesso')
      navigate('/login', { replace: true })
    },
  })

  const form = useForm<IFormType>({
    resolver: zodResolver(schema),
  })

  const onSubmit = useCallback(
    async (data: IFormType) => {
      createPasswordMutation.mutate(data)
    },
    [createPasswordMutation],
  )

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-screen w-screen items-center justify-center"
      >
        <div className="flex rounded-md border">
          <div className="dark:bg-stale-900 flex h-max w-[400px] flex-col items-center justify-center gap-10 p-8">
            <img
              className="w-[300px] "
              alt="Camila Prato Studio 3D Logo"
              src={LoginImg}
            />
            <InputField<IFormType>
              name="password"
              label="Senha"
              inputProps={{
                placeholder: 'Senha',
                type: 'password',
              }}
            />
            <InputField<IFormType>
              name="passwordConfirmation"
              label="Confirmação de senha"
              inputProps={{
                placeholder: 'Confirmação de senha',
                type: 'password',
              }}
            />

            <LoadingButton isLoading={createPasswordMutation.isPending}>
              <span>Criar</span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
