import { useCallback, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Copy, Edit2, Loader2, Plus, Search, Trash } from 'lucide-react'
import { toast } from 'sonner'

import { Pagination } from '@/components/pagination'
import { Tooltip } from '@/components/tooltip'
import { Button } from '@/components/ui/button'
import { Popover, PopoverTrigger } from '@/components/ui/popover'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { GET_PROJECT_KINDS } from '@/lib/react-query/keys'
import { deleteProjectKinds } from '@/lib/requests/project-kinds/delete_project_kinds'
import {
  getProjectKinds,
  ProjectKind,
} from '@/lib/requests/project-kinds/get_project_kinds'
import { useAppStore } from '@/store/app.store'
import { useProjectKindStore } from '@/store/project-kind/store'

import CreateUpdateProjectKindModal from './components/CreateUpdateProjectKindModal'
import { DeleteItemConfirmationDialog } from './components/DeleteItemConfirmationDialog'
import { Sheet } from '@/components/ui/sheet.tsx'
import ProjectKindsFiltersDrawer from '@/pages/ProjectKinds/components/FiltersDrawer'

export function ProjectKindsPage() {
  const queryClient = useQueryClient()

  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({
    name: '',
    is_template: 'all',
  })

  const {
    mutateAsync: handleDeleteItem,
    isPending: handleDeleteItemIsPending,
  } = useMutation({
    mutationFn: (id: string) => deleteProjectKinds(id),
    onSuccess: async () => {
      toast.success('Registro removido com sucesso.')
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECT_KINDS],
        exact: false,
      })
    },
  })

  const createUpdateProjectKindModal = useProjectKindStore(
    (store) => store.state.modals.createUpdateProjectKind,
  )

  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const setModalStatus = useProjectKindStore(
    (store) => store.actions.setModalStatus,
  )

  const { data, isLoading } = useQuery({
    queryKey: [GET_PROJECT_KINDS, page, filters],
    queryFn: () =>
      getProjectKinds({
        page,
        name: filters.name || undefined,
        isTemplate:
          filters.is_template === 'all'
            ? undefined
            : filters.is_template === 'true',
      }),
  })

  const prepareClone = useCallback(
    (item: ProjectKind) => {
      setModalStatus(
        {
          name: 'createUpdateProjectKind',
          payload: {
            ...item,
            id: null,
            name: `Copia de ${item.name}`,
            duplicate: true,
            is_template: false,
          },
        },
        true,
      )
    },
    [setModalStatus],
  )

  const renderItems = useCallback(
    (element: JSX.Element) => {
      if (isLoading) {
        return (
          <div className="flex h-[100px] w-full items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        )
      }

      if (!data?.data.length || data.meta.total_count === 0) {
        return (
          <div className="mt-10 w-full">
            <span className="flex items-center justify-center text-2xl">
              Não há items para exibir
            </span>
          </div>
        )
      }

      return element
    },
    [isLoading, data],
  )

  return (
    <>
      <section className="w-full max-w-6xl">
        <div className="flex w-full items-center justify-between">
          <span className="my-5 block text-left text-2xl">Tipos</span>
          {isAdmin && (
            <div className="flex space-x-2">
              <Button
                onClick={() => {
                  setModalStatus({ name: 'createUpdateProjectKind' }, true)
                }}
                className="flex space-x-2"
              >
                <Plus />
                <span>Novo Tipo de Projeto</span>
              </Button>
              <Tooltip label="Filtros">
                <Sheet>
                  <Button
                    size={'icon'}
                    type="button"
                    onClick={() =>
                      setModalStatus(
                        {
                          name: 'filters',
                        },
                        true,
                      )
                    }
                  >
                    <Search className="h-5 w-5" />
                  </Button>
                </Sheet>
              </Tooltip>
            </div>
          )}
        </div>

        {renderItems(
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead>Nome</TableHead>
                <TableHead>Açoes</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.data?.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell className="flex gap-2">
                      <button
                        type="button"
                        onClick={() =>
                          setModalStatus(
                            {
                              name: 'createUpdateProjectKind',
                              payload: { id: item.id },
                            },
                            true,
                          )
                        }
                      >
                        <Edit2 size={16} />
                      </button>

                      <Tooltip
                        label={
                          !item.actions.can_delete
                            ? 'Não é possível remover o registro'
                            : ''
                        }
                      >
                        <Popover>
                          <PopoverTrigger asChild>
                            <button
                              type="button"
                              disabled={!item.actions.can_delete}
                            >
                              <Trash
                                size={16}
                                color={item.actions.can_delete ? 'red' : 'gray'}
                              />
                            </button>
                          </PopoverTrigger>
                          <DeleteItemConfirmationDialog
                            isLoading={handleDeleteItemIsPending}
                            onConfirm={async () =>
                              await handleDeleteItem(item.id)
                            }
                          />
                        </Popover>
                      </Tooltip>

                      <Tooltip label="Duplicar">
                        <button
                          type="button"
                          onClick={() => prepareClone(item)}
                        >
                          <Copy size={16} color="blue" />
                        </button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>,
        )}

        {Number(data?.meta.total_pages || 0) > 1 && (
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={data?.meta.total_pages || 0}
          />
        )}
      </section>

      {createUpdateProjectKindModal.status && <CreateUpdateProjectKindModal />}

      <ProjectKindsFiltersDrawer
        onSearch={(data) => {
          setFilters({
            name: data.name,
            is_template: data.is_template,
          })
        }}
      />
    </>
  )
}
