import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { ArrowDownZA, ArrowUpAZ, Check, X } from 'lucide-react'

import { Pagination } from '@/components/pagination'
import { Tooltip } from '@/components/tooltip'
import { Badge } from '@/components/ui/badge'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { GET_PROJECTS } from '@/lib/react-query/keys'
import { getProjects, Project } from '@/lib/requests/projects/get-projects'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store.ts'
import { cn } from '@/lib/utils.ts'

type SortDirections = 'asc' | 'desc' | null

const getNewDirection = (currentDirection: SortDirections) => {
  const directions = new Map<SortDirections, SortDirections>([
    ['asc', 'desc'],
    ['desc', 'asc'],
    [null, 'asc'],
  ])
  return directions.get(currentDirection)
}

export function ProjectsListTable() {
  const filters = useProjectStore((store) => store.state.filters)
  const { page } = useProjectStore((store) => store.state.pagination)
  const setPagination = useProjectStore((store) => store.actions.setPagination)

  const [sortOptions, setSortOptions] = useState<
    Record<string, 'asc' | 'desc' | null>
  >({ created_at: 'desc' })

  const { data } = useQuery({
    queryKey: [GET_PROJECTS, sortOptions, filters, page],
    queryFn: () => getProjects(sortOptions, filters, { page }),
  })

  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const getSortTooltip = useCallback(
    (fieldName: keyof Project) => {
      const currentDirection = sortOptions[fieldName] || null

      if (currentDirection) {
        return currentDirection === 'asc' ? 'Crescente' : 'Decrescente'
      }

      return null
    },
    [sortOptions],
  )

  const defineSortField = useCallback(
    (fieldName: keyof Project) => {
      const currentDirection = sortOptions[fieldName] || null
      const newDirection = getNewDirection(currentDirection)
      setSortOptions({
        [fieldName]: newDirection || null,
      })
    },
    [sortOptions],
  )

  return (
    <>
      {!data?.data.length || data.meta.total_count === 0 ? (
        <div className="mt-10 w-full">
          <span className="flex items-center justify-center text-2xl">
            Não há projetos
          </span>
        </div>
      ) : (
        <Table className={cn('w-full cursor-pointer')}>
          <TableHeader>
            <TableRow>
              {isAdmin && (
                <TableHead>
                  <Tooltip label={getSortTooltip('created_at')}>
                    <button
                      className="flex items-center gap-1"
                      onClick={() => defineSortField('created_at')}
                    >
                      <span>Criado em</span>
                      <span>
                        {sortOptions?.created_at === 'desc' ||
                        !sortOptions?.created_at ? (
                          <ArrowDownZA size={'16px'} />
                        ) : (
                          <ArrowUpAZ size={'16px'} />
                        )}
                      </span>
                    </button>
                  </Tooltip>
                </TableHead>
              )}
              {isAdmin && (
                <TableHead>
                  <Tooltip label={getSortTooltip('code')}>
                    <button
                      className="flex items-center gap-1"
                      onClick={() => defineSortField('code')}
                    >
                      <span>Código</span>
                      <span>
                        {sortOptions?.code === 'desc' || !sortOptions?.code ? (
                          <ArrowDownZA size={'16px'} />
                        ) : (
                          <ArrowUpAZ size={'16px'} />
                        )}
                      </span>
                    </button>
                  </Tooltip>
                </TableHead>
              )}
              <TableHead>Tipo</TableHead>
              <TableHead>Título</TableHead>
              {isAdmin && <TableHead>Cliente</TableHead>}
              <TableHead>Status</TableHead>
              {isAdmin && <TableHead>Fechado</TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.data.map((row) => (
              <TableRow
                key={row.id}
                onClick={() => {
                  if (isAdmin) {
                    navigate(`/projetos/${row.id}/editar`)
                    return
                  }

                  navigate(`/projetos/${row.id}`)
                }}
              >
                {/* Criado em */}
                {isAdmin && (
                  <TableCell>
                    {row.created_at
                      ? format(new Date(row.created_at), 'dd/MM/yyyy')
                      : ''}
                  </TableCell>
                )}

                {/* Código */}
                {isAdmin && <TableCell>{row.code}</TableCell>}

                {/* Tipo */}
                <TableCell>{row.kind?.name || ''}</TableCell>

                {/* Título */}
                <TableCell>{row.title}</TableCell>

                {/* Cliente */}
                {isAdmin && <TableCell>{row.user?.name || ''}</TableCell>}
                <TableCell>
                  <Badge>{row.status.label}</Badge>
                </TableCell>

                {/* Negócio Fechado */}
                {isAdmin && (
                  <TableCell>
                    {row.done_deal ? (
                      <Check color="green" />
                    ) : (
                      <X color="red" />
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {Number(data?.meta.total_pages || 0) > 1 && (
        <Pagination
          page={page}
          setPage={(page) => setPagination({ page })}
          totalPages={data?.meta.total_pages || 0}
        />
      )}
    </>
  )
}
