import { api } from '@/lib/api'

interface CreateUpdateUserRequest {
  id?: string
  name: string
  email: string
  active: boolean
}

export function createUpdateUser<T = CreateUpdateUserRequest>(
  id: string | null,
  payload: T,
) {
  if (id) {
    return api.put(`/users/${id}`, payload)
  }

  return api.post('/users', payload)
}
