import { useFormContext, useWatch } from 'react-hook-form'

import { DatePickerForm } from '@/components/Form/datepicker.tsx'
import { Row } from '@/components/row.tsx'
import { UploadFileInput } from '@/components/upload-file.tsx'
import { CustomInput } from '@/pages/Projects/components/Form/components/custom-input.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'

interface ContratoProjetoProps {
  readonly: boolean
}

export function Contrato({ readonly }: ContratoProjetoProps) {
  const form = useFormContext()
  const contractUrl = useWatch({ control: form.control, name: 'contract_url' })
  const signedContractAt = useWatch({
    control: form.control,
    name: 'signed_contract_at',
  })

  if (readonly && !contractUrl && !signedContractAt) {
    return <></>
  }

  return (
    <ProjectCard title={'Contrato'}>
      <div className="flex w-full flex-col space-y-4">
        {(!readonly || contractUrl) && (
          <Row>
            <div className={'w-1/2'}>
              <UploadFileInput<IFormSchema>
                name="contract_file"
                readonly={readonly}
                url={contractUrl}
                onRemove={() => {
                  form.setValue('contract_file', undefined)
                  form.setValue('contract_url', '')
                }}
                inputProps={{
                  onChange: (e) => {
                    form.setValue('contract_url', '')
                    form.setValue('contract_file', e.target.files?.[0])
                  },
                }}
              />
            </div>
          </Row>
        )}

        {(!readonly || signedContractAt) && (
          <Row>
            <div className={'w-1/2'}>
              <CustomInput>
                <DatePickerForm<IFormSchema>
                  label="Data da assinatura do contrato"
                  name="signed_contract_at"
                  placeholder="Data da assinatura do contrato"
                  calendarProps={{
                    disabled: readonly,
                    mode: 'single',
                  }}
                />
              </CustomInput>
            </div>
          </Row>
        )}
      </div>
    </ProjectCard>
  )
}
