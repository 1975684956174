import { cn } from '@/lib/utils'

interface DividerProps {
  isDone: boolean
  shouldBlink: boolean
}

export function Divider({ isDone, shouldBlink }: DividerProps) {
  return (
    <div className="flex items-center justify-center">
      <div
        className={cn('min-h-[60px] w-[4px] rounded-sm bg-gray-300', {
          'bg-green-500': isDone,
          'animate-blink': shouldBlink,
        })}
      ></div>
    </div>
  )
}
