import { useFormContext, useWatch } from 'react-hook-form'

import { UploadFileInput } from '@/components/upload-file.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'

interface BriefingProps {
  readonly: boolean
}

export function Briefing({ readonly }: BriefingProps) {
  const form = useFormContext()
  const briefingUrl = useWatch({
    control: form.control,
    name: 'briefing_url',
  })

  if (readonly && !briefingUrl) return <></>

  return (
    <ProjectCard title={'Briefing'}>
      <div className="flex w-full flex-col space-y-4">
        <UploadFileInput<IFormSchema>
          name="briefing_file"
          readonly={readonly}
          url={briefingUrl}
          onRemove={() => {
            form.setValue('briefing_file', undefined)
            form.setValue('briefing_url', '')
          }}
          inputProps={{
            onChange: (e) => {
              form.setValue('briefing_file', e.target.files?.[0])
              form.setValue('briefing_url', '')
            },
          }}
        />
      </div>
    </ProjectCard>
  )
}
