interface ConditionalField {
  conditional: boolean
  renderWhenTrue?: () => JSX.Element
  renderWhenFalse?: () => JSX.Element
}

export default function ConditionalField({
  conditional,
  renderWhenFalse = () => <></>,
  renderWhenTrue = () => <></>,
}: ConditionalField) {
  if (!conditional) {
    return renderWhenFalse()
  }
  return renderWhenTrue()
}
