import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { UploadFileInput } from '@/components/upload-file.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'
import { useAppStore } from '@/store/app.store.ts'

export function Informacoes() {
  const form = useFormContext<IFormSchema>()

  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const { project_fields_attributes = [] } = form.watch()

  const textFields = useMemo(() => {
    const text = project_fields_attributes.filter(
      (item) => item.kind === 'field_text',
    )

    const currency = project_fields_attributes.filter(
      (item) => item.kind === 'field_currency',
    )

    return [...text, ...currency].map((item) => ({
      ...item,
      value: item.value,
    }))
  }, [project_fields_attributes])

  const files = useMemo(() => {
    return project_fields_attributes
      .filter((item) => item.kind === 'field_url' || item.kind === 'field_file')
      .map((item) => ({ ...item, value: String(item.value) }))
  }, [project_fields_attributes])

  if (!project_fields_attributes.length || isAdmin) return <></>

  return (
    <>
      {!!textFields.length && (
        <ProjectCard title={'Informações'}>
          <div className="flex w-full flex-col space-y-4">
            <div className="flex w-full flex-col gap-2 pb-4">
              {textFields.map((field) => (
                <div key={field.itemId}>
                  {field.name}:{' '}
                  {field.kind === 'field_currency'
                    ? Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(Number(field.value))
                    : field.value}
                </div>
              ))}
            </div>
          </div>
        </ProjectCard>
      )}

      {!!files.length && (
        <ProjectCard title={'Anexos'}>
          <div className="flex w-full flex-col space-y-4">
            {files.length > 0 && (
              <>
                <div className="flex flex-wrap gap-4">
                  {files.map((field, index) => (
                    <UploadFileInput<IFormSchema>
                      key={field.itemId}
                      label={field.name}
                      allowPreview={field.kind === 'field_file'}
                      readonly
                      name={`project_fields_attributes.${index}.value`}
                      url={field.value || ''}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </ProjectCard>
      )}
    </>
  )
}
