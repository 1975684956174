import { useNavigate } from 'react-router-dom'
import { useClerk } from '@clerk/clerk-react'
import { Info, LogOut } from 'lucide-react'

import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'
import { Tooltip } from './tooltip'

export function Header() {
  const navigate = useNavigate()
  const { user } = useClerk()

  return (
    <header className="h-12 bg-white">
      <div className="flex h-full items-center justify-between px-4">
        <div className="flex flex-1 items-center justify-center">
          {import.meta.env.VITE_APP_ENV !== 'production' && (
            <div className={'bg-red-300 p-2 rounded-2xl text-black'}>
              <span>AMBIENTE DE TESTES</span>
            </div>
          )}
        </div>

        <div className="flex h-full items-center justify-center gap-4">
          <Tooltip label="Tutorial">
            <a href={"https://youtube.com/watch?v=dLZXxyosytw"} target="_blank" rel="noreferrer">
              <Info color="black" size={'28px'} />
            </a>
          </Tooltip>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar>
                <AvatarImage src="" alt="User avatar" />
                <AvatarFallback>{user?.fullName?.[0] || 'U'}</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>
                {user?.fullName || 'Usuário'}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate('/signout', { replace: true })
                }}
                className="cursor-pointer"
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Sair</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  )
}
