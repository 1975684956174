import { useMemo, useReducer } from 'react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form'
import { Input } from '../../ui/input'

type TextInputProps<T extends FieldValues> = {
  name: Path<T>
  label: string
  placeholder: string
  disabled?: boolean
}

const moneyFormatter = Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  currencyDisplay: 'symbol',
  currencySign: 'standard',
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function MoneyInput<T extends FieldValues>(props: TextInputProps<T>) {
  const { control, getValues } = useFormContext()

  const initialValue = useMemo(() => {
    const value = getValues()[props.name]
    return value ? moneyFormatter.format(value) : ''
  }, [props, getValues])

  const [value, setValue] = useReducer((_: any, next: string) => {
    const digits = next.replace(/\D/g, '')
    return moneyFormatter.format(Number(digits) / 100)
  }, initialValue)

  function handleChange(
    realChangeFn: (realValue: number) => void,
    formattedValue: string,
  ) {
    const digits = formattedValue.replace(/\D/g, '')
    const realValue = Number(digits) / 100
    realChangeFn(realValue)
  }

  return (
    <FormField
      control={control}
      name={props.name}
      disabled={props.disabled || false}
      render={({ field }) => {
        field.value = (value ||
          Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(field.value)) as any

        return (
          <FormItem>
            <FormLabel>{props.label}</FormLabel>
            <FormControl>
              <Input
                placeholder={props.placeholder}
                type="text"
                {...field}
                onChange={(ev) => {
                  setValue(ev.target.value)
                  handleChange(field.onChange, ev.target.value)
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
