import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { MoreHorizontal } from 'lucide-react'

import {
  Pagination as ShadPagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { cn } from '@/lib/utils'

import { Button } from './ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'

interface PaginationProps {
  page: number
  totalPages: number
  setPage?: (newPage: number) => void
}

export function Pagination({ page, totalPages, setPage }: PaginationProps) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const pages = useMemo(() => {
    if (totalPages <= 5)
      return Array.from({ length: totalPages }, (_, i) => i + 1)

    return Array.from({ length: 5 }, (_, i) => {
      if (i <= 2) return i + 1
      if (i === 3) return null
      return totalPages
    })
  }, [totalPages])

  const changePage = useCallback(
    (newValue: number) => {
      let nextPage = newValue

      if (nextPage <= 0) nextPage = 1
      if (nextPage > totalPages) nextPage = totalPages

      if (setPage) {
        setPage(nextPage)
        return
      }

      const params = new URLSearchParams(searchParams.toString())
      params.set('page', nextPage.toString())
      navigate(`${pathname}?${params.toString()}`)
    },
    [totalPages, setPage, searchParams, navigate, pathname],
  )

  const renderPaginationItem = useCallback(() => {
    const notIncludedPages: number[] = []
    const totalPagesList = Array.from({ length: totalPages }, (_, i) => i + 1)

    totalPagesList.forEach((value) => {
      if (!pages.includes(value)) notIncludedPages.push(value)
    })

    return pages.map((p) => {
      if (p === null && !!notIncludedPages.length)
        return (
          <DropdownMenu key={p}>
            <DropdownMenuTrigger asChild>
              <Button
                className={cn({
                  'rounded border': notIncludedPages.includes(page),
                })}
                size={'icon'}
                variant={'ghost'}
              >
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {notIncludedPages.map((p) => (
                <PaginationLink
                  key={p}
                  isActive={p === page}
                  onClick={() => changePage(p)}
                >
                  {p}
                </PaginationLink>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      return (
        <PaginationLink
          key={p}
          isActive={p === page}
          onClick={() => changePage(p || 0)}
        >
          {p}
        </PaginationLink>
      )
    })
  }, [totalPages, pages, page, changePage])

  return (
    <div className="my-10 flex flex-col items-center justify-center gap-4">
      <ShadPagination className="flex-1">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className={cn('cursor-pointer', {
                'cursor-not-allowed': page <= 1,
              })}
              onClick={() => changePage(page - 1)}
            />
          </PaginationItem>

          {renderPaginationItem()}

          <PaginationItem>
            <PaginationNext
              className={cn('cursor-pointer', {
                'cursor-not-allowed': page >= totalPages,
              })}
              onClick={() => changePage(page + 1)}
            />
          </PaginationItem>
        </PaginationContent>
      </ShadPagination>
    </div>
  )
}
