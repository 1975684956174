import { ReactNode } from 'react'
import { useSession } from '@clerk/clerk-react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export function PrivateRoute({ children }: { children?: ReactNode }) {
  const { isSignedIn, isLoaded } = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  if (!isLoaded) return

  if (!isSignedIn) {
    if (location.pathname === '/login') {
      return children || <Outlet />
    }

    navigate('/login')
    return
  }

  if (location.pathname === '/login') {
    navigate('/')
    return
  }

  return children || <Outlet />
}
