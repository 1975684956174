import { PopoverClose } from '@radix-ui/react-popover'

import { LoadingButton } from '@/components/Form/button'
import { PopoverContent } from '@/components/ui/popover'

interface DeleteItemConfirmationDialogProps {
  onConfirm: () => void
  isLoading: boolean
}

export function DeleteItemConfirmationDialog({
  onConfirm,
  isLoading,
}: DeleteItemConfirmationDialogProps) {
  return (
    <PopoverContent className="w-80">
      <div className="flex flex-col gap-2">
        <span className="text-sm">Deseja excluir o registro ?</span>

        <div className="flex w-full justify-end">
          <PopoverClose>
            <LoadingButton
              size={'sm'}
              variant={'destructive'}
              type="button"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              Confirmar
            </LoadingButton>
          </PopoverClose>
        </div>
      </div>
    </PopoverContent>
  )
}
