import { ChangeEvent } from 'react'
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import { RadioGroup, RadioGroupItem } from '../ui/radio-group'

interface RadioGroupOption {
  value: string
  label: string
}

interface RadioGroupFormFieldProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  options: RadioGroupOption[]
  disabled?: boolean
}

export function RadioGroupFormField<T extends FieldValues>({
  name,
  label,
  options = [],
  disabled,
}: RadioGroupFormFieldProps<T>) {
  const { control } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => {
                field.onChange(
                  value as ChangeEvent<Element> | PathValue<T, Path<T>>,
                )
              }}
              defaultValue={field.value}
              className="flex flex-col space-y-1"
            >
              {options.map((opt) => (
                <FormItem
                  key={opt.value}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem disabled={disabled} value={opt.value} />
                  </FormControl>
                  <FormLabel className="font-normal">{opt.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
