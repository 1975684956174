import { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'
import { z } from 'zod'

import { RadioGroupFormField } from '@/components/Form/radio-group'
import { TextareaField } from '@/components/Form/textarea'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { UploadFileInput } from '@/components/upload-file'
import { GET_PROJECT_DETAILS } from '@/lib/react-query/keys'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id'
import { TabsType } from '@/pages/Projects/components/Form/form.tsx'
import {
  ProjectImageStatus,
  reviewImages,
} from '@/lib/requests/projects/review-images.ts'

const FormSchema = z.object({
  projectTestImages: z.array(
    z
      .object({
        itemId: z.string().optional(),
        title: z.string(),
        status: z.nativeEnum(ProjectImageStatus, {
          errorMap: () => {
            return { message: 'Campo inválido' }
          },
        }),
        url: z.string({ required_error: 'Campo obrigatório' }),
        comment: z.string({ coerce: true }).default(''),
      })
      .refine(
        (values) => {
          return !(values.status === 'rejected' && !values.comment)
        },
        {
          message: 'Campo obrigatório',
          path: ['comment'],
        },
      ),
  ),
})

type IFormSchema = z.infer<typeof FormSchema>

interface ChangeStatusFormProps {
  project: ProjectDetails
  setTab: (tab: TabsType) => void
}

function ImagesReviewForm({ project, setTab }: ChangeStatusFormProps) {
  const queryClient = useQueryClient()

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      projectTestImages: project.project_images.map((img) => ({
        comment: img.comment || undefined,
        title: img.title,
        itemId: img.id,
        status: img.status as ProjectImageStatus,
        url: img.url,
      })),
    },
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: 'projectTestImages',
  })

  const projectTestImages = useWatch({
    control: form.control,
    name: 'projectTestImages',
  })

  const testImagesReviewMutation = useMutation({
    mutationFn: async (data: IFormSchema) => {
      if (!project.id) return

      await reviewImages(project.id, {
        project_images_attributes: data.projectTestImages.map((img) => ({
          status: img.status,
          id: img.itemId,
          comment: img.comment || null,
        })),
      })
    },
    onSuccess: async () => {
      toast.success('Projeto atualizado com sucesso')
      setTab('project')
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECT_DETAILS],
        exact: false,
      })
      setShowConfirmDialog(false)
    },
  })

  const handleSubmit = useCallback(() => {
    const data = form.getValues()
    testImagesReviewMutation.mutate(data)
  }, [form, testImagesReviewMutation])

  const saveButtonIsDisabled = useMemo(() => {
    return (
      !project.project_images.some((item) => item.status === 'awaiting') ||
      projectTestImages.some((item) => item.status === 'awaiting')
    )
  }, [project?.project_images, projectTestImages])

  const handleOpenConfirmationModal = useCallback(() => {
    if (saveButtonIsDisabled) return

    setShowConfirmDialog(true)
  }, [saveButtonIsDisabled])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOpenConfirmationModal)}>
        <div
          className={
            'w-full border-solid border border-gray-300 rounded-md p-2'
          }
        >
          <span
            className={'text-md block text-center mb-2 uppercase font-bold'}
          >
            O que analisar nas imagens teste:
          </span>

          <span className={'text-sm block'}>
            As imagens testes são exatamente como nas imagens finais - a única
            diferença é a resolução da imagem e marca d'água, por isso peço que
            analise todos os seguintes pontos:
          </span>

          <ol className={'list-decimal list-inside'}>
            <li className={'text-sm'}>
              Iluminação - localização e tipos das luzes, suas tonalidades, a
              luz natural, se tem alguma área que deseje iluminar mais, como as
              luzes atuam saindo de pendentes, tudo relaconado à iluminação da
              cena.
            </li>
            <li className={'text-sm'}>
              Materiais - é necessário analisar todos os materiais e seus
              acabamentos; os tecidos, se estão no acabamento correto (veludo,
              suede, linho, algodão, sarja, tricot, etc.); se os materiais são
              brilhosos ou acetinados; tonalidades, os tipos de netalizado; as
              cores de tintas de parede, etc. Tudo relacionado aos acabamentos
              de materiais. Caso não esteja visualizando algum material com
              nitidez, peço que pontue, para que eu realize uma imagem teste
              mais aproximada para que você verifique a configuração do
              material.
            </li>
            <li className={'text-sm'}>
              Verifique se todos os blocos estão locados corretamente; caso
              queiram modificar algum bloco, peço que pontue nesta fase de
              testes.
            </li>
            <li className={'text-sm'}>
              As cenas que são enviadas são cenas de sugestão, caso queira
              modificar, pontue na fase teste. Analise o formato das imagens.
            </li>
            <li className={'text-sm'}>
              Será solicitada a confirmação, se todas as imagens estão corretas
              (incluindo as cenas), e uma vez que fir confirmado e produzidas as
              imagens finais, não realizamos alterações, somente mediante taxa
              adicional.
            </li>
            <li className={'text-sm'}>
              Se forem confirmadas mais imagens do que o combinado inicialmente,
              será cobrado.
            </li>
          </ol>
        </div>

        <div className="w-full space-y-6">
          {fields.map((item, index) => (
            <div key={item.id}>
              {index !== 0 && <div className={'bg-gray-200 h-0.5 w-full'} />}

              <span className={'my-2 block'}>{item.title}</span>

              <div className="flex items-start space-x-4">
                <UploadFileInput<IFormSchema>
                  readonly
                  label={''}
                  name={`projectTestImages.${index}.url`}
                  url={item.url}
                />
                <TextareaField<IFormSchema>
                  label=""
                  inputProps={{
                    className: 'h-44 resize-none',
                    placeholder: 'Observação',
                    disabled:
                      project.project_images[index].status !== 'awaiting',
                  }}
                  name={`projectTestImages.${index}.comment`}
                />
              </div>

              <RadioGroupFormField<IFormSchema>
                name={`projectTestImages.${index}.status`}
                disabled={
                  project.project_images[index].status !==
                  ProjectImageStatus.awaiting
                }
                options={[
                  {
                    label: 'Aprovado',
                    value: ProjectImageStatus.approved,
                  },
                  {
                    label: 'Reprovar',
                    value: ProjectImageStatus.rejected,
                  },
                ]}
              />
            </div>
          ))}

          <Button type="submit" disabled={saveButtonIsDisabled}>
            <span>Salvar</span>
          </Button>
        </div>

        <AlertDialog open={showConfirmDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Enviar revisão de imagens</AlertDialogTitle>
              <AlertDialogDescription>
                Tem certeza que deseja enviar a revisão de imagens ?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setShowConfirmDialog(false)}>
                Cancelar
              </AlertDialogCancel>
              <AlertDialogAction type="button" onClick={handleSubmit}>
                {testImagesReviewMutation.isPending ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <span>Enviar</span>
                )}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </form>
    </Form>
  )
}

interface ImagesReviewProps {
  projectData: ProjectDetails | null
  setTab: (tab: TabsType) => void
}

export function ImagesReview({ projectData, setTab }: ImagesReviewProps) {
  if (!projectData) return null

  return <ImagesReviewForm project={projectData} setTab={setTab} />
}
