import { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from '@clerk/clerk-react'

interface PageProps extends PropsWithChildren {}

export const AdminLayout = ({ children }: PageProps) => {
  const navigate = useNavigate()
  const { isSignedIn, isLoaded, session } = useSession()

  useEffect(() => {
    if (!isLoaded) return

    if (!isSignedIn) {
      navigate('/login')
      return
    }

    const roles: string[] = (session?.user?.publicMetadata?.roles ||
      []) as string[]
    const isAdmin = roles.includes('project-manager-admin') || false

    if (!isAdmin) {
      navigate('/')
    }
  }, [isLoaded, isSignedIn, navigate, session])

  return children
}
