import { api } from '@/lib/api'

export enum ProjectImageStatus {
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
}

interface ReviewProjectImagesRequest {
  project_images_attributes?: {
    id?: string
    status: ProjectImageStatus
    comment: string | null
  }[]
}

export async function reviewImages(
  id: string,
  data: ReviewProjectImagesRequest,
) {
  await api.post(`/projects/${id}/review_images`, data)
}
