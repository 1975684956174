import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_KINDS,
  GET_USERS,
} from '@/lib/react-query/keys'
import { getProjectKinds } from '@/lib/requests/project-kinds/get_project_kinds'
import { getProjectById } from '@/lib/requests/projects/get-project-by-id'
import { getUsers } from '@/lib/requests/users/get-users'
import { useAppStore } from '@/store/app.store'

import { ProjectBaseForm } from './form'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { useMemo } from 'react'

interface ProjectFormProps {
  readonly?: boolean
  projectId?: string
}

export function ProjectForm({ readonly, projectId }: ProjectFormProps) {
  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: [GET_USERS],
    queryFn: () => getUsers({ paginationOptions: { page: 1, perPage: 100 } }),
    enabled: isAdmin,
  })

  const { data: project, isError: fetchProjectIsError } = useQuery({
    queryKey: [GET_PROJECT_DETAILS, projectId],
    queryFn: () => getProjectById(projectId || ''),
    enabled: !!projectId,
  })

  const { data: projectKinds, isFetching: isFetchingProjectKinds } = useQuery({
    queryKey: [GET_PROJECT_KINDS],
    queryFn: () =>
      getProjectKinds({ page: 1, perPage: 100, isTemplate: false }),
    enabled: isAdmin,
  })

  const disableAllFields = useMemo(() => {
    return !!readonly || !!project?.archived
  }, [readonly, project?.archived])

  if (fetchProjectIsError) {
    navigate('/')
    return
  }

  if (isFetchingUsers || isFetchingProjectKinds) {
    return (
      <div className="w-full h-[300px] flex items-center justify-center text-xl">
        <LoadingContainer />
      </div>
    )
  }

  return (
    <ProjectBaseForm
      projectKinds={projectKinds?.data || []}
      users={
        users?.data.length
          ? users?.data.map((user) => ({
              ...user,
              label: user.name,
              value: user.id,
            }))
          : []
      }
      projectId={projectId}
      initialData={project}
      readonly={disableAllFields}
    />
  )
}
