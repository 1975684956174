import { PropsWithChildren, useEffect } from 'react'

interface PageProps extends PropsWithChildren {
  title: string
}

export const Page = ({ children, title }: PageProps) => {
  useEffect(() => {
    document.title = `Gestor de Projetos - ${title}`

    return () => {
      document.title = `Gestor de Projetos`
    }
  }, [title])

  return children
}
