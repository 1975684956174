import SmallLogo from '@/assets/small-logo-header-2.png'
import { Home, List, Users } from 'lucide-react'
import { Tooltip } from './tooltip'
import { cn } from '@/lib/utils'
import { Link, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { useAppStore } from '@/store/app.store.ts'

interface Item {
  title: string
  icon: JSX.Element
  href: string
  root?: boolean
}

function MenuItem({ item }: { item: Item }) {
  const location = useLocation()

  const isActive = useMemo(() => {
    if (location.pathname === '/' && item.root) return true

    return !item.root && location.pathname.includes(item.href)
  }, [item, location])

  return (
    <Tooltip
      side={'right'}
      key={item.href}
      title={item.title}
      label={item.title}
    >
      <Link
        to={{
          search: item.href === location.pathname ? location.search : '',
          pathname: item.href,
        }}
        className={cn(
          'group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full text-lg font-semibold md:h-8 md:w-8 md:text-base',
          {
            'bg-primary text-primary-foreground': isActive,
          },
        )}
      >
        {item.icon}
      </Link>
    </Tooltip>
  )
}

export function MenuBar() {
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  const menuItems = useMemo((): Item[] => {
    const items = [
      {
        href: '/projetos',
        root: false,
        icon: (
          <Home className="h-4 w-4  transition-all group-hover:scale-110" />
        ),
        title: 'Projetos',
      },
    ]

    if (isAdmin) {
      items.push(
        {
          href: '/clientes',
          root: false,
          icon: (
            <Users className="h-4 w-4  transition-all group-hover:scale-110" />
          ),
          title: 'Clientes',
        },
        {
          href: '/tipos_projetos',
          root: false,
          icon: (
            <List className="h-4 w-4  transition-all group-hover:scale-110" />
          ),
          title: 'Tipos de Projetos',
        },
      )
    }

    return items
  }, [isAdmin])

  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4">
        <div className={'px-2 pt-2'}>
          <Tooltip label={'Portal do Cliente'} side={'right'}>
            <img alt={'logo'} src={SmallLogo} />
          </Tooltip>
        </div>

        <div className={'bg-gray-200 h-0.5 w-full'} />

        {menuItems.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </nav>
    </aside>
  )
}
