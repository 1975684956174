import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClerk } from '@clerk/clerk-react'

export default function SignoutPage() {
  const navigate = useNavigate()
  const { signOut, session } = useClerk()

  useEffect(() => {
    signOut({ sessionId: session?.id }).then(() => {
      localStorage.clear()
      navigate('/login')
    })
  }, [navigate, signOut, session])

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <>Carregando...</>
    </div>
  )
}
