import { ReactNode, useCallback, useEffect } from 'react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { DownloadCloud, FileArchive, FileIcon, Upload, X } from 'lucide-react'
import mime from 'mime'

import { mountFileUrl } from '@/lib/mount-file-url'
import { cn } from '@/lib/utils'

import { Button } from './ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form'
import { InputProps } from './ui/input'
import { Tooltip } from './tooltip'

type Size = 'small' | 'default'

interface UploadFileInputProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  url?: string
  readonly?: boolean
  onRemove?: () => void
  className?: string
  inputProps?: InputProps
  allowPreview?: boolean
  size?: Size
}

interface PreviewProps {
  size?: Size
  label?: string
  onClick: () => void
}

function RenderPreview({
  children,
  label = '',
}: {
  children: ReactNode
  label?: string
}) {
  return <Tooltip label={`Visualizar ${label}`}>{children}</Tooltip>
}

function RenderExternalLinkPreview({ size, label, onClick }: PreviewProps) {
  return (
    <RenderPreview label={label}>
      <DownloadCloud
        onClick={onClick}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderPDFPreview({ size, label, onClick }: PreviewProps) {
  return (
    <RenderPreview label={label}>
      <FileIcon
        onClick={onClick ? () => onClick() : () => {}}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderFilePreview({ size, label, onClick }: PreviewProps) {
  return (
    <RenderPreview label={label}>
      <FileArchive
        onClick={onClick}
        className={cn('h-12 w-12', {
          'h-10 w-10': size === 'small',
        })}
      />
    </RenderPreview>
  )
}

function RenderImagePreview({
  size,
  label,
  url,
  onClick,
}: PreviewProps & { url: string }) {
  return (
    <RenderPreview label={label}>
      <img
        className={cn('h-36 w-36', {
          'h-24 w-24': size === 'small',
        })}
        src={url}
        alt="preview"
        onClick={onClick}
      />
    </RenderPreview>
  )
}

export function UploadFileInput<T extends FieldValues>({
  label,
  name,
  url,
  readonly,
  className = '',
  onRemove,
  inputProps,
  allowPreview = true,
  size = 'default',
}: UploadFileInputProps<T>) {
  const { control } = useFormContext<T>()

  const preview = useCallback(
    (fieldValue?: File | null): string => {
      if (url) return allowPreview ? mountFileUrl(url) : url
      if (fieldValue instanceof File) return URL.createObjectURL(fieldValue)
      return ''
    },
    [allowPreview, url],
  )

  const getType = useCallback(
    (fieldValue: File | null) => {
      if (url) return mime.getType(url)
      if (fieldValue instanceof File) return fieldValue.type
      return ''
    },
    [url],
  )

  useEffect(() => {
    document.addEventListener(
      'drop',
      (e) => {
        e.preventDefault()
      },
      false,
    )
  }, [])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={cn('max-w-44', {
            'max-w-28': size === 'small',
          })}
        >
          <FormLabel asChild>
            <span
              className={cn('block max-h-44 truncate', {
                'max-h-28': size === 'small',
              })}
            >
              {label}
            </span>
          </FormLabel>
          <FormControl>
            <>
              <input
                {...field}
                {...inputProps}
                value={undefined}
                type="file"
                className="hidden"
                id={name}
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  e.target.value = null
                }}
              />

              <div
                onDragOver={(e) => {
                  e.preventDefault()
                }}
                onDrop={(e) => {
                  field.onChange(e.dataTransfer.files[0])
                }}
                className={cn(
                  'relative flex h-44 w-44 cursor-pointer items-center justify-center rounded border-2 border-dashed',
                  className,
                  {
                    'h-28 w-28': size === 'small',
                  },
                )}
              >
                {preview(field.value) && (
                  <>
                    {getType(field.value)?.includes('image') && (
                      <RenderImagePreview
                        onClick={() => {
                          window.open(preview(field.value), '_blank')
                        }}
                        url={preview(field.value)}
                        size={size}
                        label={label}
                      />
                    )}

                    {getType(field.value)?.includes('pdf') && (
                      <RenderPDFPreview
                        size={size}
                        label={label}
                        onClick={() => {
                          window.open(preview(field.value), '_blank')
                        }}
                      />
                    )}

                    {getType(field.value) &&
                      !getType(field.value)?.includes('pdf') &&
                      !getType(field.value)?.includes('image') && (
                        <RenderFilePreview
                          size={size}
                          label={label}
                          onClick={() => {
                            window.open(preview(field.value), '_blank')
                          }}
                        />
                      )}

                    {!getType(field.value) && (
                      <RenderExternalLinkPreview
                        label={label}
                        onClick={() => {
                          window.open(preview(field.value), '_blank')
                        }}
                      />
                    )}
                  </>
                )}

                {!preview(field.value) && (
                  <Button
                    type="button"
                    disabled={readonly}
                    onClick={() => {
                      const el = document.getElementById(name)
                      if (!el) return
                      el.click()
                    }}
                    size={'icon'}
                  >
                    <Upload
                      className={cn('h-6 w-6', {
                        'h-5 w-5': size === 'small',
                      })}
                    />
                  </Button>
                )}

                {!readonly && preview(field.value) && (
                  <button
                    type="button"
                    onClick={() => {
                      if (typeof onRemove === 'function') {
                        onRemove()
                      }
                    }}
                    className="absolute -right-2 -top-2 rounded-full bg-red-600"
                  >
                    <X
                      className={cn('h-6 w-6', {
                        'h-5 w-5': size === 'small',
                      })}
                      color="white"
                    />
                  </button>
                )}
              </div>
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
