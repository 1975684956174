import React from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { ClerkProvider } from '@clerk/clerk-react'
import { Toaster } from 'sonner'

import { ReactQueryProvider } from '@/lib/react-query/provider.tsx'
import { Router } from '@/router.tsx'

import '@/entrypoints/global.css'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    debug: import.meta.env.VITE_APP_ENV === 'development',
    environment: import.meta.env.VITE_APP_ENV,
    enabled: import.meta.env.VITE_APP_ENV !== 'development',
    // Performance Monitoring
    tracesSampleRate: 1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', `${window.location.origin}/api/`],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
} else {
  console.log('SENTRY DSN não informado. Sentry não será inicializado.')
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <Router />
        <Toaster position="top-right" richColors />
      </ClerkProvider>
    </ReactQueryProvider>
  </React.StrictMode>,
)
