import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '@/lib/requests/users/get-users.ts'
import { ModalType } from '@/store/user/types.ts'

interface UserStoreState {
  modals: {
    createUpdateUserForm: { status: boolean; payload?: User | null }
  }
}

interface UserStoreActions {
  setModalStatus: (data: ModalType, status: boolean) => void
}

interface UserStore {
  state: UserStoreState
  actions: UserStoreActions
}

const INITIAL_STATE: UserStoreState = {
  modals: {
    createUpdateUserForm: { status: false, payload: null },
  },
}

export const useUserStore = create<UserStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<UserStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setModalStatus: (data, status) => {
          setState(({ state }: UserStore) => {
            let payloadLocal: any = null

            if ('payload' in data) {
              payloadLocal = data.payload
            }

            const { name } = data
            state.modals[name] = { status, payload: payloadLocal || null }
          })
        },
      },
    }
  }),
)
