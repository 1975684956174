import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Plus, Search } from 'lucide-react'

import { Tooltip } from '@/components/tooltip'
import { Button } from '@/components/ui/button'
import { Sheet } from '@/components/ui/sheet'
import { GET_USERS } from '@/lib/react-query/keys.ts'
import { getUsers } from '@/lib/requests/users/get-users.ts'
import { useAppStore } from '@/store/app.store'
import { useProjectStore } from '@/store/project/store'

import ProjectsFiltersDrawer from './components/FiltersDrawer'
import { ProjectsListTable } from './components/ProjectsListTable'
import { ProjectsTabs } from '@/pages/Projects/components/ProjectsTabs'

export function ProjectsPage() {
  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const selectedTab = useProjectStore((store) => store.state.selectedTab)

  const { data: users } = useQuery({
    queryKey: [GET_USERS],
    queryFn: () => getUsers({ paginationOptions: { perPage: 100 } }),
    enabled: isAdmin,
  })

  const setProjectModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const openFilterDrawer = useCallback(() => {
    setProjectModalStatus({ name: 'filters' }, true)
  }, [setProjectModalStatus])

  return (
    <section className="w-full max-w-6xl">
      <div className="flex w-full items-center justify-between">
        <span className="my-5 block text-left text-2xl">
          {isAdmin ? 'Todos os projetos' : 'Meus Projetos'}
        </span>
        {isAdmin && (
          <div className="flex space-x-2">
            {selectedTab === 'active' && (
              <Button
                onClick={() => navigate('/projetos/novo')}
                className="flex space-x-2"
              >
                <Plus />
                <span>Novo Projeto</span>
              </Button>
            )}

            <Tooltip label="Filtros">
              <Sheet>
                <Button size={'icon'} type="button" onClick={openFilterDrawer}>
                  <Search className="h-5 w-5" />
                </Button>
              </Sheet>
            </Tooltip>
          </div>
        )}
      </div>

      {isAdmin ? <ProjectsTabs /> : <ProjectsListTable />}

      {isAdmin && (
        <ProjectsFiltersDrawer
          users={
            users?.data.map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            })) || []
          }
        />
      )}
    </section>
  )
}
