import { format } from 'date-fns'

interface TextProps {
  doneDate?: string
  statusItem: { label: string }
  username?: string
}

export function Text({ doneDate, username, statusItem }: TextProps) {
  return (
    <div className="ml-2 mt-5 flex flex-col text-xs">
      <span>{statusItem.label}</span>
      <span>
        {doneDate ? format(new Date(doneDate), 'dd/MM/yyyy HH:mm') : ''}{' '}
        {`${username ? ` - ${username}` : ''}`}
      </span>
    </div>
  )
}
