export const paymentMethodOptions = [
  {
    label: 'Boleto',
    value: 'boleto',
  },
  {
    label: 'Cartão de Crédito',
    value: 'credit_card',
  },
  {
    label: 'Pix',
    value: 'pix',
  },
]
