import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'

import { LoadingButton } from '@/components/Form/button'
import { InputField } from '@/components/Form/Input/base'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { GET_USERS } from '@/lib/react-query/keys'
import { useUserStore } from '@/store/user/store.ts'
import { createUpdateUser } from '@/lib/requests/users/create-update-user.ts'
import { toast } from 'sonner'
import { SwitchFormField } from '@/components/Form/switch.tsx'

const FormSchema = z.object({
  id: z.string().optional(),
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  email: z
    .string({ coerce: true })
    .min(1, 'Campo obrigatório')
    .email({ message: 'E-mail inválido' })
    .default(''),
  active: z.boolean({ coerce: true }).default(true),
})

type IFormSchema = z.infer<typeof FormSchema>

export function CreateUpdateUserModal() {
  const queryClient = useQueryClient()
  const setModalStatus = useUserStore((store) => store.actions.setModalStatus)
  const userFormModal = useUserStore(
    (store) => store.state.modals.createUpdateUserForm,
  )

  const { mutate: handleCreateOrUpdate, isPending } = useMutation({
    mutationFn: (data: IFormSchema) =>
      createUpdateUser(data.id || null, {
        active: data.active,
        email: data.email,
        name: data.name,
      }),
    onSuccess: async (data) => {
      const msg =
        data.status === 201
          ? 'Usuário criado com sucesso'
          : 'Usuário atualizado com sucesso'

      toast.success(msg)

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GET_USERS],
        }),
      ])

      close()
    },
  })

  const close = useCallback(() => {
    setModalStatus({ name: 'createUpdateUserForm' }, false)
  }, [setModalStatus])

  const form = useForm<IFormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      id: undefined,
      email: '',
      name: '',
      active: true,
    },
    values: {
      active: userFormModal.payload?.active ?? true,
      name: userFormModal.payload?.name || '',
      email: userFormModal.payload?.email || '',
      id: userFormModal.payload?.id,
    },
  })

  const onSubmit = useCallback(
    async (data: IFormSchema) => {
      handleCreateOrUpdate(data)
    },
    [handleCreateOrUpdate],
  )

  const modalTitle = useMemo(() => {
    if (userFormModal.payload?.id) return 'Editar Cliente'
    return 'Novo Cliente'
  }, [userFormModal.payload?.id])

  return (
    <Dialog open={userFormModal.status} onOpenChange={close}>
      <DialogContent className="max-h-[90%] overflow-auto sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{modalTitle}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <InputField<IFormSchema>
                name={'name'}
                label={'Nome'}
                inputProps={{ placeholder: 'Nome' }}
              />

              <InputField<IFormSchema>
                name={'email'}
                label={'E-mail'}
                inputProps={{
                  placeholder: 'E-mail',
                  disabled: !!userFormModal.payload?.email,
                }}
              />

              <SwitchFormField<IFormSchema> label="Ativo" name="active" />
            </div>

            <div className="mt-10 flex flex-col gap-4">
              <div className="mt-10 flex w-full justify-end">
                <LoadingButton isLoading={isPending}>
                  <span>Salvar</span>
                </LoadingButton>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
