import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getProjectKindDetails } from '@/lib/requests/project-kinds/get_project_kind_details'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id'

import { TimelineItem } from './item'

interface StatusTimelineProps {
  timelineData: ProjectDetails['project_history']
  projectKindId: string
}

export function StatusTimeline({
  timelineData,
  projectKindId,
}: StatusTimelineProps) {
  const { data: projectStatusOptionsByKind } = useQuery({
    queryKey: ['get-status-timeline', projectKindId],
    queryFn: () => getProjectKindDetails(projectKindId),
  })

  const searchInHistoryData = useCallback(
    (statusValue: string) => {
      const history = timelineData.find((item) => item.to === statusValue)
      return history || null
    },
    [timelineData],
  )

  return (
    <div className="mt-9 flex flex-col items-start">
      {projectStatusOptionsByKind?.status.map((item, index, originalList) => (
        <TimelineItem
          key={item.id}
          currentIndex={index}
          currentStatus={{ label: item.label }}
          item={searchInHistoryData(item.id)}
          nextItem={searchInHistoryData(originalList[index + 1]?.id)}
          statusList={projectStatusOptionsByKind.status}
        />
      ))}
    </div>
  )
}
