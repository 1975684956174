import { api } from '@/lib/api'

import { MetaPaginate } from '../types'

export interface ProjectBudgetItem {
  id: string
  value: number
  date: string
  accepted: boolean
  budget_url: string | null
}

export interface ProjectBudgets {
  data: ProjectBudgetItem[]
  meta: MetaPaginate
}

export interface PaginateOptions {
  page: number
  per_page?: number
}

export async function getProjectBudgetsByProjectId(
  projectId: string,
  paginationOptions: PaginateOptions,
) {
  const { page = 1 } = paginationOptions

  const result = await api.get<ProjectBudgets>(
    `/projects/${projectId}/budgets`,
    {
      params: { page },
    },
  )
  return result.data
}
