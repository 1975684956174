import { useFormContext, useWatch } from 'react-hook-form'

import { InputField } from '@/components/Form/Input/base.tsx'
import { UploadFileInput } from '@/components/upload-file.tsx'
import { CustomInput } from '@/pages/Projects/components/Form/components/custom-input.tsx'
import { ProjectCard } from '@/pages/Projects/components/Form/components/project-card.tsx'
import { IFormSchema } from '@/pages/Projects/components/Form/validate.ts'
import { ExternalLink } from 'lucide-react'

interface EntregaFinalProps {
  readonly: boolean
}

export function EntregaFinal({ readonly }: EntregaFinalProps) {
  const form = useFormContext<IFormSchema>()
  const deliveryTermUrl = useWatch({
    control: form.control,
    name: 'delivery_term_url',
  })
  const deliveryDocsUrl = useWatch({
    control: form.control,
    name: 'delivered_docs_url',
  })

  if (readonly && !deliveryDocsUrl && !deliveryTermUrl) return <></>

  return (
    <ProjectCard title={'Entrega Final'}>
      <div className="flex w-full flex-col space-y-4">
        {(!readonly || deliveryTermUrl) && (
          <UploadFileInput<IFormSchema>
            name="delivery_term_file"
            label={'Termo de entrega'}
            readonly={readonly}
            url={deliveryTermUrl}
            onRemove={() => {
              form.setValue('delivery_term_file', undefined)
              form.setValue('delivery_term_url', '')
            }}
            inputProps={{
              onChange: (e) => {
                form.setValue('delivery_term_file', e.target.files?.[0])
                form.setValue('delivery_term_url', '')
              },
            }}
          />
        )}

        <div className={'w-1/2'}>
          {readonly ? (
            <>
              {deliveryDocsUrl && (
                <a
                  href={deliveryDocsUrl}
                  target={'_blank'}
                  className={'text-blue-500 underline'}
                  rel="noreferrer"
                >
                  <span className={'flex gap-2'}>
                    <ExternalLink /> Link dos documentos enviados
                  </span>
                </a>
              )}
            </>
          ) : (
            <CustomInput>
              <InputField<IFormSchema>
                name={'delivered_docs_url'}
                label={'Link dos documentos enviados'}
                inputProps={{
                  disabled: readonly,
                  placeholder: 'https://drive.google.com/...',
                }}
              />
            </CustomInput>
          )}
        </div>
      </div>
    </ProjectCard>
  )
}
