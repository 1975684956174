import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSession, useUser } from '@clerk/clerk-react'
import { useIsFetching } from '@tanstack/react-query'

import { Header } from '@/components/header'
import { InfiniteLoadingProgressBar } from '@/components/loading-progress-bar'
import { useAppStore } from '@/store/app.store'
import { MenuBar } from '@/components/menu-bar.tsx'

export function AuthRootLayout() {
  const { user } = useUser()
  const { session } = useSession()
  const isFetching = useIsFetching()
  const setIsAdmin = useAppStore((state) => state.actions.setIsAdmin)

  useEffect(() => {
    const roles = (user?.publicMetadata?.roles || []) as string[]
    setIsAdmin(roles.includes('project-manager-admin'))
  }, [setIsAdmin, user])

  useEffect(() => {
    if (import.meta.env.VITE_APP_ENV === 'development') {
      session
        ?.getToken({ template: 'custom-dev' })
        .then((res) => {
          console.log({ res })
        })
        .catch(console.log)
    }
  }, [session])

  return (
    <>
      <MenuBar />

      {isFetching ? (
        <InfiniteLoadingProgressBar />
      ) : (
        <div className="h-1"></div>
      )}

      <Header />

      <main className="m-auto h-full max-w-6xl overflow-auto px-5 xl:px-0">
        <Outlet />
      </main>
    </>
  )
}
