import { api } from '@/lib/api'

import { MetaPaginate } from '../types'

export interface ProjectFinancialItem {
  id: string
  value: number
  date: string
  payment_method: string
  paid: boolean
  receipt_url: string | null
}

export interface ProjectFinancials {
  data: ProjectFinancialItem[]
  meta: MetaPaginate
}

export interface PaginateOptions {
  page: number
  per_page?: number
}

export async function getProjectFinancialByProjectId(
  projectId: string,
  paginationOptions: PaginateOptions,
) {
  const { page = 1 } = paginationOptions

  const result = await api.get<ProjectFinancials>(
    `/projects/${projectId}/financials`,
    {
      params: { page },
    },
  )
  return result.data
}
