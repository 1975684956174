import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AppStoreState {
  isAdmin: boolean
}

interface AppStoreActions {
  setIsAdmin: (isAdmin: boolean) => void
}

interface AppStore {
  state: AppStoreState
  actions: AppStoreActions
}

const INITIAL_STATE: AppStoreState = {
  isAdmin: false,
}

export const useAppStore = create<AppStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<AppStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setIsAdmin: (isAdmin: boolean) => {
          setState(({ state }: AppStore) => {
            state.isAdmin = isAdmin
          })
        },
      },
    }
  }),
)
