import { produce } from 'immer'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import {
  ImageReviewModalType,
  ModalType,
  ProjectBudgetFormModalType,
  ProjectFinancialFormModalType,
  UpdateStatusModalType,
} from './types'

interface ProjectStoreState {
  selectedTab: 'active' | 'archived'
  pagination: {
    page: number
    perPage: number
  }
  filters: {
    done_deal: string
    title: string
    code: string
    user_id: string
    archived: boolean
  }
  modals: {
    filters: { status: boolean }
    updateStatus: {
      status: boolean
      payload?: UpdateStatusModalType['payload'] | null
    }
    financialForm: {
      status: boolean
      payload?: ProjectFinancialFormModalType['payload'] | null
    }
    budgetForm: {
      status: boolean
      payload?: ProjectBudgetFormModalType['payload'] | null
    }
    imageReview: {
      status: boolean
      payload?: ImageReviewModalType['payload'] | null
    }
  }
}

interface ProjectStoreActions {
  setModalStatus: (data: ModalType, status: boolean) => void
  setFilters: (filters: Partial<ProjectStoreState['filters']>) => void
  setPagination: (filters: Partial<ProjectStoreState['pagination']>) => void
  setSelectedTab: (selectedTab: ProjectStoreState['selectedTab']) => void
}

interface ProjectStore {
  state: ProjectStoreState
  actions: ProjectStoreActions
}

export const DEFAULT_PROJECT_PAGINATION = {
  page: 1,
  perPage: 50,
}

export const DEFAULT_PROJECT_FILTERS = {
  done_deal: '',
  user_id: '',
  code: '',
  title: '',
  archived: false,
}

const INITIAL_STATE: ProjectStoreState = {
  selectedTab: 'active',
  pagination: DEFAULT_PROJECT_PAGINATION,
  filters: DEFAULT_PROJECT_FILTERS,
  modals: {
    filters: { status: false },
    updateStatus: { status: false, payload: null },
    financialForm: { status: false, payload: null },
    imageReview: { status: false },
    budgetForm: { status: false, payload: null },
  },
}

export const useProjectStore = create<ProjectStore>()(
  devtools((set) => {
    const setState = (fn: any) => set(produce<ProjectStore>(fn))

    return {
      state: INITIAL_STATE,
      actions: {
        setSelectedTab: (selectedTab) => {
          setState(({ state }: ProjectStore) => {
            state.selectedTab = selectedTab
          })
        },
        setFilters: (filters) => {
          setState(({ state }: ProjectStore) => {
            state.filters = {
              ...state.filters,
              ...filters,
            }
          })
        },
        setPagination: (pagination) => {
          setState(({ state }: ProjectStore) => {
            state.pagination = {
              ...state.pagination,
              ...pagination,
            }
          })
        },
        setModalStatus: (data, status) => {
          setState(({ state }: ProjectStore) => {
            let payloadLocal: any = null

            if ('payload' in data) {
              payloadLocal = data.payload
            }

            const { name } = data
            state.modals[name] = {
              status,
              payload: payloadLocal || null,
            }
          })
        },
      },
    }
  }),
)
