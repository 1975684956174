export const ACCEPTED_FILE_TYPE = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]

export const MAX_FILE_SIZE_MB = 10
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024

export const validateFileType = {
  check: (file: File) => {
    if (file && !ACCEPTED_FILE_TYPE.includes(file.type)) {
      return false
    }
    return true
  },
  opts: {
    message: `Formato inválido - Aceito: ${ACCEPTED_FILE_TYPE.join(', ')}`,
  },
}

export const validateFileSize = {
  check: (file: File) => {
    if (file && file.size > MAX_FILE_SIZE) {
      return false
    }
    return true
  },
  opts: { message: `Tamanho máximo: ${MAX_FILE_SIZE_MB}MB` },
}
