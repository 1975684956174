import { useCallback } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { toast } from 'sonner'
import { z } from 'zod'

import { LoadingButton } from '@/components/Form/button'
import { CheckboxField } from '@/components/Form/checkbox'
import { DatePickerForm } from '@/components/Form/datepicker'
import { MoneyInput } from '@/components/Form/Input/money-input'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { UploadFileInput } from '@/components/upload-file'
import {
  GET_PROJECT_BUDGETS,
  GET_PROJECT_DETAILS,
} from '@/lib/react-query/keys'
import {
  updateProject,
  UpdateProjectRequest,
} from '@/lib/requests/projects/update-project'
import { uploadFileToS3 } from '@/lib/requests/uploads/upload-file-to-s3'
import {
  validateFileSize,
  validateFileType,
} from '@/lib/zod-validators/validate-file'
import { useProjectStore } from '@/store/project/store'

const FormSchema = z.object({
  date: z.date({
    required_error: 'Campo obrigatório',
  }),
  value: z.number().min(0.01, 'Campo obrigatório').default(0),
  accepted: z.boolean().default(false),
  _destroy: z.boolean().optional(),
  budget_url: z.string().optional().nullable(),
  budget_file: z
    .any()
    .optional()
    .transform((file) => {
      return file || undefined
    })
    .refine(validateFileSize.check, validateFileSize.opts)
    .refine(validateFileType.check, validateFileType.opts),
  id: z.string().optional(),
})

type IFormSchema = z.infer<typeof FormSchema>

export function ProjectBudgetFormModal() {
  const queryClient = useQueryClient()
  const budgetFormModal = useProjectStore(
    (store) => store.state.modals.budgetForm,
  )
  const setModalStatus = useProjectStore(
    (store) => store.actions.setModalStatus,
  )

  const projectId = budgetFormModal.payload?.projectId
  const itemData = budgetFormModal.payload?.budgetItemData

  const { mutateAsync: handleUpdate, isPending } = useMutation({
    mutationFn: async ({
      data,
      files,
    }: {
      data: Pick<UpdateProjectRequest, 'project_budgets_attributes'>
      files: { budget_file: File }
    }) => {
      if (files.budget_file && data.project_budgets_attributes?.length) {
        data.project_budgets_attributes[0].budget_url = await uploadFileToS3(
          files.budget_file as File,
        )
      }

      return updateProject(projectId || '', data)
    },
  })

  const close = useCallback(() => {
    setModalStatus({ name: 'budgetForm' }, false)
  }, [setModalStatus])

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    values: {
      _destroy: false,
      date: itemData?.date ? parseISO(itemData.date) : (null as any),
      accepted: itemData?.accepted || false,
      value: itemData?.value ? Number(itemData.value) : 0,
      budget_url: itemData?.budget_url as any,
      id: itemData?.id as any,
    },
  })

  const budgetUrl = useWatch({ control: form.control, name: 'budget_url' })

  const onSubmit = useCallback(
    async (data: IFormSchema) => {
      await handleUpdate(
        {
          files: { budget_file: data.budget_file },
          data: {
            project_budgets_attributes: [
              {
                _destroy: false,
                value: Number(data.value),
                date: format(data.date, 'yyyy-MM-dd'),
                accepted: data.accepted || false,
                budget_url: data.budget_url,
                id: data.id,
              },
            ],
          },
        },
        {
          onSuccess: async () => {
            toast.success('Registro salvo com sucesso')

            await Promise.all([
              queryClient.invalidateQueries({
                queryKey: [GET_PROJECT_DETAILS],
                exact: false,
              }),
              queryClient.invalidateQueries({
                queryKey: [GET_PROJECT_BUDGETS, projectId],
              }),
            ])
            close()
          },
        },
      )
    },
    [close, handleUpdate, projectId, queryClient],
  )

  return (
    <Dialog open={budgetFormModal.status} onOpenChange={close}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <span>{itemData?.id ? 'Editar registro' : 'Criar registro'}</span>
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-6"
          >
            <div className="flex w-full flex-col gap-4">
              <DatePickerForm<IFormSchema>
                label="Data de envio"
                name={`date`}
                placeholder="Data de envio"
                calendarProps={{
                  mode: 'single',
                }}
              />

              <MoneyInput<IFormSchema>
                label="Valor"
                name="value"
                placeholder="Valor"
              />

              <div className="flex justify-start">
                <UploadFileInput<IFormSchema>
                  label={`Orçamento`}
                  size="small"
                  name="budget_file"
                  url={budgetUrl || ''}
                  onRemove={() => {
                    form.setValue('budget_file', undefined)
                    form.setValue('budget_url', null)
                  }}
                  inputProps={{
                    onChange: (e) => {
                      form.setValue('budget_url', null)
                      form.setValue('budget_file', e.target.files?.[0])
                    },
                  }}
                />
              </div>

              <CheckboxField<IFormSchema>
                name={`accepted`}
                label="Orçamento aceito"
              />
            </div>

            <div className="mt-10 flex w-full justify-end">
              <LoadingButton isLoading={isPending}>
                <span>Salvar</span>
              </LoadingButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
