import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { InputField } from '@/components/Form/Input/base'
import { SelectField } from '@/components/Form/select'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { useAppStore } from '@/store/app.store'
import { useProjectKindStore } from '@/store/project-kind/store.ts'

interface IFormSchema {
  name: string
  is_template: string
}

const DEFAULT_VALUES = {
  name: '',
  is_template: 'all',
}

interface ProjectKindsFiltersDrawer {
  onSearch: (data: IFormSchema) => void
}

export default function ProjectKindsFiltersDrawer({
  onSearch,
}: ProjectKindsFiltersDrawer) {
  const {
    state: { isAdmin },
  } = useAppStore()
  const isOpened = useProjectKindStore((store) => store.state.modals.filters)
  const setModalStatus = useProjectKindStore(
    (store) => store.actions.setModalStatus,
  )

  const form = useForm<IFormSchema>({
    defaultValues: {
      name: DEFAULT_VALUES.name,
      is_template: DEFAULT_VALUES.is_template,
    },
  })

  const handleSubmit = useCallback(
    (data: IFormSchema) => {
      onSearch({
        name: data.name,
        is_template: data.is_template,
      })
    },
    [onSearch],
  )

  const close = useCallback(() => {
    setModalStatus({ name: 'filters' }, false)
  }, [setModalStatus])

  return (
    <Sheet open={isOpened.status && isAdmin} onOpenChange={close}>
      <SheetContent>
        <div className="flex flex-col gap-4">
          <SheetHeader>
            <SheetTitle>Filtros</SheetTitle>
          </SheetHeader>

          <Form {...form}>
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <InputField<IFormSchema>
                name={'name'}
                label={'Nome'}
                inputProps={{
                  placeholder: 'Nome',
                }}
              />

              <SelectField<IFormSchema>
                name={'is_template'}
                placeholder="Template"
                label={'Template'}
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                  { label: 'Todos', value: 'all' },
                ]}
              />

              <div className="flex justify-end gap-2">
                <Button
                  variant={'secondary'}
                  type="button"
                  onClick={() => form.reset(DEFAULT_VALUES)}
                >
                  <span>Limpar</span>
                </Button>
                <Button type="submit">
                  <span>Buscar</span>
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}
