import { api } from '@/lib/api'

export interface CreateUpdateProjectKindInput {
  name: string
  is_template: boolean
  project_kind_statuses_attributes: {
    id?: string
    is_default: boolean
    label: string
    _destroy: boolean
    order: number
  }[]
}

export async function createUpdateProjectKind(
  id: string | null,
  data: CreateUpdateProjectKindInput,
) {
  let result = null

  if (id) {
    result = await api.put(`/project_kinds/${id}`, data)
  } else {
    result = await api.post(`/project_kinds`, data)
  }

  return result.data
}
