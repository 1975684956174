import { api } from '@/lib/api'

export async function uploadFileToS3(file: File): Promise<string> {
  const formData = new FormData()
  formData.append('file', file)
  const {
    data: { url },
  } = await api.post('/uploads/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return url
}
