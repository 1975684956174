import { useFormContext, UseFormSetValue } from 'react-hook-form'
import { File as FileIcon, Trash } from 'lucide-react'

import { CheckboxField } from '@/components/Form/checkbox'
import { InputField } from '@/components/Form/Input/base'
import { MoneyInput } from '@/components/Form/Input/money-input'
import { SelectField } from '@/components/Form/select'
import { Tooltip } from '@/components/tooltip'
import { Button } from '@/components/ui/button'
import { mountFileUrl } from '@/lib/mount-file-url'

import { IFormSchema } from '../Form/validate'

interface FieldRowProps {
  index: number
  setValue: UseFormSetValue<IFormSchema>
  disabled: boolean
}

export function FieldRow({ disabled, index, setValue }: FieldRowProps) {
  const { watch } = useFormContext<IFormSchema>()

  const { project_fields_attributes } = watch()

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="flex w-full flex-row items-start space-x-2">
          <div className="w-[30%]">
            <SelectField<IFormSchema>
              disabled={disabled}
              name={`project_fields_attributes.${index}.kind`}
              label={'Tipo'}
              options={[
                { label: 'Texto', value: 'field_text' },
                { label: 'Link', value: 'field_url' },
                { label: 'Moeda', value: 'field_currency' },
                { label: 'Arquivo', value: 'field_file' },
              ]}
              onChange={() => {
                setValue(`project_fields_attributes.${index}.value`, '')
              }}
            />
          </div>

          <div className="w-[30%]">
            <InputField<IFormSchema>
              inputProps={{
                disabled,
              }}
              label="Nome"
              name={`project_fields_attributes.${index}.name` as any}
            />
          </div>

          {(project_fields_attributes[index].kind === 'field_text' ||
            project_fields_attributes[index].kind === 'field_url') && (
            <div className="flex-1">
              <InputField<IFormSchema>
                inputProps={{
                  disabled,
                }}
                label="Valor"
                name={`project_fields_attributes.${index}.value`}
              />
            </div>
          )}

          {project_fields_attributes[index].kind === 'field_currency' && (
            <div className="flex-1">
              <MoneyInput
                label="Valor"
                name={`project_fields_attributes.${index}.value`}
                placeholder="Valor"
              />
            </div>
          )}

          {project_fields_attributes[index].kind === 'field_file' && (
            <div className="flex flex-1 flex-row items-end space-x-2">
              <InputField<IFormSchema>
                name={`project_fields_attributes.${index}.value`}
                label={'Arquivo'}
                inputProps={{
                  type: 'file',
                  disabled,
                  value: undefined,
                  onChange: (e) => {
                    setValue(`project_fields_attributes.${index}.value`, '')
                    setValue(
                      `project_fields_attributes.${index}.file`,
                      e.target.files?.[0],
                    )
                  },
                }}
              />
              {(project_fields_attributes[index].value ||
                project_fields_attributes[index].file) && (
                <Tooltip label="Visualizar">
                  <Button
                    type="button"
                    variant={'ghost'}
                    size="icon"
                    onClick={() => {
                      const url = project_fields_attributes[index].file
                        ? URL.createObjectURL(
                            project_fields_attributes[index].file as any,
                          )
                        : mountFileUrl(
                            project_fields_attributes[index].value
                              ? String(project_fields_attributes[index].value)
                              : '',
                          )

                      window.open(url, '_blank')
                    }}
                  >
                    <FileIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          )}

          {!disabled && (
            <div className={'mt-8 flex'}>
              <Button
                type="button"
                variant={'destructive'}
                size={'icon'}
                onClick={() =>
                  setValue(`project_fields_attributes.${index}._destroy`, true)
                }
              >
                <Trash />
              </Button>
            </div>
          )}
        </div>

        <CheckboxField<IFormSchema>
          disabled={disabled}
          name={`project_fields_attributes.${index}.show_to_client`}
          label="Mostrar para cliente"
        />
      </div>
    </>
  )
}
