import { LoadingButton } from '@/components/Form/button'
import { PopoverContent } from '@/components/ui/popover'
import { PopoverClose } from '@radix-ui/react-popover'

interface SendUserAccessConfirmationDialogProps {
  onConfirm: () => void
  isLoading: boolean
  user: { name: string; email: string }
}

export function SendUserAccessConfirmationDialog({
  onConfirm,
  isLoading,
  user,
}: SendUserAccessConfirmationDialogProps) {
  return (
    <PopoverContent className="w-80">
      <div className="flex flex-col gap-2">
        <span className="text-sm">
          Deseja enviar o e-mail com o <strong>acesso</strong> para o cliente{' '}
          {user.name} ?
        </span>

        <span className="text-xs">E-mail: {user.email}</span>

        <div className="flex w-full justify-end">
          <PopoverClose>
            <LoadingButton
              size={'sm'}
              type="button"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              Confirmar
            </LoadingButton>
          </PopoverClose>
        </div>
      </div>
    </PopoverContent>
  )
}
