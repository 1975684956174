import { PropsWithChildren } from 'react'
import { Loader2 } from 'lucide-react'

import { Button, ButtonProps } from '../ui/button'

interface LoadingButtonProps extends PropsWithChildren<ButtonProps> {
  isLoading?: boolean
}

export function LoadingButton({
  isLoading = false,
  children,
  ...rest
}: LoadingButtonProps) {
  return (
    <Button
      type="submit"
      className="flex w-full items-center justify-center"
      {...rest}
    >
      {isLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : children}
    </Button>
  )
}
