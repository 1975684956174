import { FieldValues, Path, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form'
import { Input, InputProps } from '../../ui/input'

interface InputFieldProps<T extends FieldValues> {
  name: Path<T>
  label: string
  inputProps?: InputProps
}

export function InputField<T extends FieldValues>({
  name,
  label,
  inputProps = {},
}: InputFieldProps<T>) {
  const { control } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...field}
              {...inputProps}
              onChange={(e) => {
                if (inputProps?.onChange && inputProps?.type === 'file') {
                  inputProps.onChange(e)
                } else {
                  field.onChange(e)
                }
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
