import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { ProjectHistory } from '@/lib/requests/projects/get-project-by-id'

import { StatusTimeline } from './StatusTimeline'

interface TimelineDrawerProps {
  isOpen: boolean
  close: () => void
  timelineData: ProjectHistory[]
  projectKindId: string
}

export function TimelineDrawer({
  isOpen,
  close,
  timelineData,
  projectKindId,
}: TimelineDrawerProps) {
  return (
    <Sheet open={isOpen} onOpenChange={close}>
      <SheetContent className="overflow-auto">
        <SheetHeader>
          <SheetTitle>Linha do projeto</SheetTitle>
        </SheetHeader>
        <StatusTimeline
          timelineData={timelineData}
          projectKindId={projectKindId}
        />
      </SheetContent>
    </Sheet>
  )
}
