import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { Archive, ArrowLeft, Check, X } from 'lucide-react'

import { Tooltip } from '@/components/tooltip'
import { Button } from '@/components/ui/button'
import { ProjectDetails } from '@/lib/requests/projects/get-project-by-id'
import { useAppStore } from '@/store/app.store'

interface FormTitleProps {
  readonly: boolean
  isNewProject: boolean
  details: ProjectDetails | undefined
}

export function FormTitle({ isNewProject, readonly, details }: FormTitleProps) {
  const navigate = useNavigate()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  return (
    <div className="flex items-center space-x-4">
      <Button
        variant={'ghost'}
        onClick={() => (readonly ? navigate('/') : navigate(-1))}
      >
        <ArrowLeft />
      </Button>

      <div className="my-5 flex items-center gap-2">
        {readonly ? (
          <span className="text-2xl">Detalhes - {details?.title}</span>
        ) : (
          <span className="text-2xl">
            {!isNewProject ? `Editar - ${details?.title}` : 'Novo Projeto'}
          </span>
        )}

        {isAdmin && !isNewProject && (
          <>
            {details?.done_deal && details?.done_deal_at ? (
              <Tooltip
                label={`Negócio fechado em ${format(
                  new Date(details.done_deal_at),
                  'dd/MM/yyyy',
                )}`}
              >
                <Check color="green" />
              </Tooltip>
            ) : (
              <Tooltip label="Negócio não fechado">
                <X color="red" />
              </Tooltip>
            )}

            {details?.archived && (
              <Tooltip label={'Projeto arquivado'}>
                <Archive />
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  )
}
