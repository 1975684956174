import { ProjectsListTable } from '@/pages/Projects/components/ProjectsListTable'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx'
import {
  DEFAULT_PROJECT_FILTERS,
  DEFAULT_PROJECT_PAGINATION,
  useProjectStore,
} from '@/store/project/store.ts'

export type TabType = 'active' | 'archived'

export const ProjectsTabs = () => {
  const setFilters = useProjectStore((store) => store.actions.setFilters)
  const setPagination = useProjectStore((store) => store.actions.setPagination)
  const selectedTab = useProjectStore((store) => store.state.selectedTab)
  const setSelectedTab = useProjectStore(
    (store) => store.actions.setSelectedTab,
  )

  return (
    <Tabs
      defaultValue={selectedTab}
      value={selectedTab}
      onValueChange={(tab) => {
        setSelectedTab(tab as TabType)
        setFilters({ ...DEFAULT_PROJECT_FILTERS, archived: tab === 'archived' })
        setPagination(DEFAULT_PROJECT_PAGINATION)
      }}
    >
      <TabsList className={'gap-2'}>
        <TabsTrigger value={'active'}>Ativos</TabsTrigger>
        <TabsTrigger value={'archived'}>Arquivados</TabsTrigger>
      </TabsList>

      <TabsContent value={'active'}>
        <ProjectsListTable />
      </TabsContent>
      <TabsContent value={'archived'}>
        <ProjectsListTable />
      </TabsContent>
    </Tabs>
  )
}
