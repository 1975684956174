import Cookies from 'js-cookie'

import { api } from './api'

export function mountFileUrl(key: string) {
  if (!key) return ''
  const token = Cookies.get('__session')
  if (!token) return ''
  return `${api.defaults.baseURL}/uploads?key=${key}&access_token=${token}`
}
