import { api } from '@/lib/api'

import { CreateProjectRequest } from './create-project'

export enum ProjectImageStatus {
  awaiting = 'awaiting',
  approved = 'approved',
  rejected = 'rejected',
}

export interface UpdateProjectFinancials {
  id?: string
  value: number
  payment_method: string
  paid: boolean
  date: string
  _destroy: boolean
}

export interface UpdateProjectBudgets {
  id?: string
  value: number
  date: string
  accepted: boolean
  budget_url?: string | null
  _destroy?: boolean
}

export interface UpdateProjectFinancialRequest {
  project_financials_attributes?: Partial<UpdateProjectFinancials>[]
}

export interface UpdateProjectBudgetsRequest {
  project_budgets_attributes?: Partial<UpdateProjectBudgets>[]
}

export interface UpdateProjectRequest extends Partial<CreateProjectRequest> {
  status?: {
    id: string
    label: string
    order: number
    project_kind: {
      id: string
      name: string
    }
  }
  project_images_attributes?: {
    url: string
    title: string
    id?: string
    status: ProjectImageStatus
    _destroy?: boolean
    comment?: string
  }[]
  project_fields_attributes?: {
    id: string
    kind: string
    name: string
    value: string | null
    show_to_client: boolean
    _destroy: boolean
  }[]
  project_financials_attributes?: {
    id?: string
    value: number
    payment_method: string
    paid: boolean
    date: string
    receipt_url?: string | null
    _destroy: boolean
  }[]
  project_budgets_attributes?: UpdateProjectBudgets[]
}

export async function updateProject<T = UpdateProjectRequest>(
  id: string,
  data: T,
) {
  await api.put(`/projects/${id}`, data)
}
