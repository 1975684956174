import axios from 'axios'
import Cookies from 'js-cookie'

const api = axios.create({
  baseURL: `/api/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

api.interceptors.request.use(function (config) {
  if (config.headers.Authorization) return config
  const token = Cookies.get('__session')
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const redirectStatusCodes = [401, 403]

    if (redirectStatusCodes.includes(error?.response?.status)) {
      window.location.replace(`/signout`)
    }

    return Promise.reject(error)
  },
)

export { api }
