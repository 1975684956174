import type { ClerkAPIError } from '@clerk/types'

import { clerkErrors } from './clerk-errors'

export interface APIResponseError {
  errors: ClerkAPIError[]
}

function getField(paramName: string) {
  const usernameParams = ['identifier', 'account', 'username']
  const passwordParams = ['password', 'code']

  if (usernameParams.includes(paramName)) {
    return 'email'
  }

  if (passwordParams.includes(paramName)) {
    return 'password'
  }

  return ''
}

export function parseError(err: APIResponseError): {
  field: 'password' | 'email' | ''
  message: string
} {
  if (!err) {
    return { field: '', message: '' }
  }

  if (err.errors) {
    return {
      field: getField(err.errors[0].meta?.paramName || ''),
      message: clerkErrors[err.errors[0].code || ''],
    }
  }

  throw err
}
