import { Check } from 'lucide-react'

import { cn } from '@/lib/utils'

interface IndicatorProps {
  isDone: boolean
}

export function Indicator({ isDone }: IndicatorProps) {
  return (
    <div className="flex h-16 w-12 items-center justify-center text-white">
      <div
        className={cn(
          'flex h-10 w-10 items-center justify-center rounded-full bg-gray-500',
          {
            'bg-green-500': isDone,
          },
        )}
      >
        <Check />
      </div>
    </div>
  )
}
