import { useParams } from 'react-router-dom'

import { ProjectForm } from '../components/Form'
import { useAppStore } from '@/store/app.store.ts'

export default function ProjectEdit() {
  const params = useParams<'id'>()
  const isAdmin = useAppStore((store) => store.state.isAdmin)

  return <ProjectForm readonly={!isAdmin} projectId={params.id} />
}
