import { FieldValues, Path, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import { Textarea, TextareaProps } from '../ui/textarea'

interface InputFieldProps<T extends FieldValues> {
  name: Path<T>
  label: string
  inputProps?: TextareaProps
}

export function TextareaField<T extends FieldValues>({
  name,
  label,
  inputProps = {},
}: InputFieldProps<T>) {
  const { control } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea {...field} {...inputProps} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
