export const clerkErrors = {
  identification_deletion_failed:
    'Você não pode excluir sua última identificação.',
  phone_number_exists:
    'Este número de telefone já está em uso. Por favor, tente outro.',
  form_identifier_not_found: 'Credenciais inválidas.',
  captcha_invalid:
    'Não foi possível se inscrever devido a falhas nas validações de segurança. Por favor, atualize a página para tentar novamente ou entre em contato com o suporte para obter mais ajuda.',
  form_password_pwned:
    'Esta senha foi encontrada como parte de uma violação e não pode ser usada, por favor, tente outra senha.',
  form_username_invalid_length: 'Usuário inválido.',
  form_username_invalid_character: 'Usuário inválido.',
  form_param_format_invalid: '',
  form_param_format_invalid__email_address:
    'O endereço de e-mail deve ser um endereço de e-mail válido.',
  form_password_length_too_short: '',
  form_param_nil: '',
  form_code_incorrect: '',
  form_password_incorrect: 'Credenciais inválidas.',
  not_allowed_access: '',
  form_identifier_exists: '',
  form_password_validation_failed: 'Credenciais inválidas.',
  form_password_not_strong_enough: 'Sua senha não é forte o suficiente.',
  form_password_size_in_bytes_exceeded:
    'Sua senha excedeu o número máximo de bytes permitidos, por favor, encurte-a ou remova alguns caracteres especiais.',
  passwordComplexity: {
    sentencePrefix: 'Sua senha deve conter',
    minimumLength: '{{length}} ou mais caracteres',
    maximumLength: 'menos de {{length}} caracteres',
    requireNumbers: 'um número',
    requireLowercase: 'uma letra minúscula',
    requireUppercase: 'uma letra maiúscula',
    requireSpecialCharacter: 'um caractere especial',
  },
  zxcvbn: {
    notEnough: 'Sua senha não é forte o suficiente.',
    couldBeStronger:
      'Sua senha funciona, mas poderia ser mais forte. Tente adicionar mais caracteres.',
    goodPassword: 'Sua senha atende a todos os requisitos necessários.',
    warnings: {
      straightRow:
        'Letras que vêm em sequência teclado são fáceis de adivinhar.',
      keyPattern: 'Padrões curtos de teclado são fáceis de adivinhar.',
      simpleRepeat: 'Caracteres repetidos, como "aaa" são fáceis de adivinhar.',
      extendedRepeat:
        'Padrões de caracteres repetidos, como "abcabcabc" são fáceis de adivinhar.',
      sequences:
        'Sequências comuns de caracteres, como "abc" são fáceis de adivinhar.',
      recentYears: 'Anos recentes são fáceis de adivinhar.',
      dates: 'Datas são fáceis de adivinhar.',
      topTen: 'Esta é uma senha muito usada.',
      topHundred: 'Esta é uma senha usada frequentemente.',
      common: 'Esta é uma senha comumente usada.',
      similarToCommon: 'Esta é semelhante a uma senha comumente usada.',
      wordByItself: 'Palavras simples são fáceis de adivinhar.',
      namesByThemselves: 'Nomes ou sobrenomes são fáceis de adivinhar.',
      commonNames: 'Nomes e sobrenomes comuns são fáceis de adivinhar.',
      userInputs: 'Não deve haver nenhum dado pessoal ou relacionado à página.',
      pwned: 'Sua senha foi exposta por uma violação de dados na Internet.',
    },
    suggestions: {
      l33t: "Evite substituições previsíveis de letras, como '@' por 'a'.",
      reverseWords:
        'Evite utilizar palavras comuns escritas de "trás para frente".',
      allUppercase: 'Utilize apenas algumas letras maiúsculas, não todas.',
      capitalization: 'Utilize outras letras maiúsculas, além do que primeira.',
      dates: 'Evite datas e anos associados a você.',
      recentYears: 'Evite anos recentes.',
      associatedYears: 'Evite anos associados a você.',
      sequences: 'Evite sequências comuns de caracteres.',
      repeated: 'Evite palavras e caracteres repetidos.',
      longerKeyboardPattern:
        'Use padrões de teclado mais longos e mude a direção da digitação várias vezes.',
      anotherWord: 'Adicione mais palavras que são menos comuns.',
      useWords: 'Use várias palavras, mas evite frases comuns.',
      noNeed:
        'Você pode criar senhas fortes sem usar símbolos, números ou letras maiúsculas.',
      pwned: 'Se você usar esta senha em outro lugar, você deve mudá-la.',
    },
  },
  form_param_max_length_exceeded__name:
    'O nome não deve exceder 256 caracteres.',
  form_param_max_length_exceeded__first_name:
    'O primeiro nome não deve exceder 256 caracteres.',
  form_param_max_length_exceeded__last_name:
    'O sobrenome não deve exceder 256 caracteres.',
} as { [index: string]: any }
