import { useCallback, useMemo } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Plus, XCircle } from 'lucide-react'
import { toast } from 'sonner'
import { z } from 'zod'

import { LoadingButton } from '@/components/Form/button'
import { CheckboxField } from '@/components/Form/checkbox'
import ConditionalField from '@/components/Form/conditional-field'
import { InputField } from '@/components/Form/Input/base'
import { SelectField } from '@/components/Form/select'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import {
  GET_PROJECT_KIND_DETAILS,
  GET_PROJECT_KINDS,
} from '@/lib/react-query/keys'
import {
  createUpdateProjectKind,
  CreateUpdateProjectKindInput,
} from '@/lib/requests/project-kinds/create_update_project_kind'
import { getProjectKindDetails } from '@/lib/requests/project-kinds/get_project_kind_details'
import {
  CopyProjectKindItem,
  ProjectKindItem,
} from '@/lib/requests/project-kinds/types'
import { cn } from '@/lib/utils'
import { useAppStore } from '@/store/app.store'
import { useProjectKindStore } from '@/store/project-kind/store'

interface RenderProjectKindModalFormProps {
  initialData?: ProjectKindItem | CopyProjectKindItem | null
}

const ProjectKindStatusesAttributesSchema = z.preprocess(
  (val: any) => {
    return val.filter(
      (item: any) => !item._destroy || (item.id && item._destroy),
    )
  },
  z.array(
    z
      .object({
        id: z.string().optional(),
        label: z.string().min(1, 'Campo obrigatório'),
        _destroy: z.boolean().default(false),
      })
      .or(
        z.object({
          id: z.any(),
          label: z.any(),
          _destroy: z.literal(true),
        }),
      ),
  ),
)

const FormSchema = z.object({
  id: z.string().optional(),
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  is_template: z.boolean().default(false),
  default_status: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  project_kind_statuses_attributes: ProjectKindStatusesAttributesSchema,
})

type IFormSchema = z.infer<typeof FormSchema>

export default function CreateUpdateProjectKindModal() {
  const isAdmin = useAppStore((store) => store.state.isAdmin)
  const createUpdateProjectKind = useProjectKindStore(
    (store) => store.state.modals.createUpdateProjectKind,
  )

  const modalPayload = createUpdateProjectKind.payload
  const itemId = modalPayload?.id
  const isClone = !!modalPayload && 'duplicate' in modalPayload

  const { data } = useQuery({
    queryKey: [GET_PROJECT_KIND_DETAILS, itemId || ''],
    queryFn: () => {
      if (!itemId) return
      if (isClone) return
      return getProjectKindDetails(itemId)
    },
    enabled: !!itemId && !isClone,
  })

  const initialData = useMemo(() => {
    if (!modalPayload) return null
    if (isClone) return modalPayload
    return data
  }, [data, isClone, modalPayload])

  if (itemId && !data) return <></>
  if (!isAdmin) return <></>

  return <RenderProjectKindModalForm initialData={initialData} />
}

function RenderProjectKindModalForm({
  initialData,
}: RenderProjectKindModalFormProps) {
  const queryClient = useQueryClient()
  const setModalStatus = useProjectKindStore(
    (store) => store.actions.setModalStatus,
  )

  const { mutateAsync: handleCreateOrUpdate, isPending } = useMutation({
    mutationFn: (data: CreateUpdateProjectKindInput) =>
      createUpdateProjectKind(initialData?.id || null, data),
  })

  const close = useCallback(async () => {
    queryClient.removeQueries({
      queryKey: [GET_PROJECT_KIND_DETAILS],
      exact: false,
    })

    setModalStatus({ name: 'createUpdateProjectKind' }, false)
  }, [queryClient, setModalStatus])

  const form = useForm<IFormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      id: initialData?.id || undefined,
      name: initialData?.name || '',
      is_template: initialData?.is_template || false,
      default_status:
        initialData?.status.find((item) => item.is_default)?.id || undefined,
      project_kind_statuses_attributes: initialData
        ? initialData.status.map((item) => ({
            _destroy: false,
            id: item.id,
            label: item.label,
          }))
        : [{ id: undefined, _destroy: false, label: '' }],
    },
  })

  const { fields, append, update, move } = useFieldArray({
    control: form.control,
    keyName: 'itemId',
    name: 'project_kind_statuses_attributes',
  })

  const addField = () => {
    append({
      id: undefined,
      label: '',
      _destroy: false,
    })
  }

  const onSubmit = useCallback(
    async (data: IFormSchema) => {
      const statusPayload = data.project_kind_statuses_attributes
        .map((item, index) => {
          return {
            _destroy: item._destroy,
            is_default:
              data.default_status === item.id ||
              data.default_status === item.label,
            label: item.label,
            id: item.id,
            order: index + 1,
          }
        })
        .sort((item) => {
          return item.is_default ? 0 : -1
        })

      const payload: CreateUpdateProjectKindInput = {
        name: data.name,
        is_template: data.is_template,
        project_kind_statuses_attributes: statusPayload,
      }

      await handleCreateOrUpdate(payload, {
        onSuccess: () => {
          toast.success('Registro salvo com sucesso')
        },
      })
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECT_KINDS],
        exact: false,
      })
      close()
    },
    [close, handleCreateOrUpdate, queryClient],
  )

  const showDeleteRowIcon = useCallback(
    (registerId?: string) => {
      if (initialData) {
        const currentRegister = initialData.status.find(
          (item) => item.id === registerId,
        )
        if (currentRegister && currentRegister.actions.can_delete === false)
          return false
      }

      return fields.filter((item) => !item._destroy).length > 1
    },
    [fields, initialData],
  )

  const modalTitle = useMemo(() => {
    if (initialData?.id) return 'Editar tipo de projeto'
    if (initialData?.name) return 'Duplicar tipo de projeto'
    return 'Novo tipo de projeto'
  }, [initialData])

  const statusOptions = useWatch({
    control: form.control,
    name: 'project_kind_statuses_attributes',
  }).filter((item) => !!item.label)

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (result.source && result.destination) {
          move(result.source.index, result.destination?.index)
        }
      }}
    >
      <Dialog open={true} onOpenChange={close}>
        <DialogContent className="max-h-[90%] overflow-auto sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{modalTitle}</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4">
                <InputField<IFormSchema>
                  name={'name'}
                  label={'Nome'}
                  inputProps={{ placeholder: 'Nome' }}
                />

                <SelectField<IFormSchema>
                  name={`default_status`}
                  label={'Status padrão'}
                  placeholder="Selecione um status padrão"
                  disabled={
                    statusOptions.filter((item) => Boolean(item.label))
                      .length === 0
                  }
                  options={
                    statusOptions.length
                      ? statusOptions
                          .filter((item) => !item._destroy)
                          .map((item) => {
                            return {
                              label: item.label,
                              value: item.id || item.label,
                            }
                          })
                      : []
                  }
                />
                <CheckboxField label="Template" name="is_template" />
              </div>

              <div className="mt-10 flex flex-col gap-4">
                <div className="flex items-center justify-start gap-2">
                  <span className="text-lg">Lista de status</span>
                  <Button variant={'ghost'} type="button" onClick={addField}>
                    <Plus color="green" />
                  </Button>
                </div>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-col gap-4"
                    >
                      {fields.map((field, index) => (
                        <ConditionalField
                          key={field.itemId}
                          conditional={!field._destroy}
                          renderWhenTrue={() => {
                            return (
                              <Draggable
                                draggableId={field.itemId}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    className={cn(
                                      'space relative flex w-full items-end gap-4 rounded border border-dashed border-gray-400 p-4',
                                    )}
                                  >
                                    <div className="flex-1">
                                      <InputField<IFormSchema>
                                        name={`project_kind_statuses_attributes.${index}.label`}
                                        label={'Nome'}
                                        inputProps={{ placeholder: 'Status' }}
                                      />
                                    </div>

                                    {showDeleteRowIcon(field.id) && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          update(index, {
                                            ...field,
                                            _destroy: true,
                                          })

                                          if (
                                            form.getValues('default_status') ===
                                            field.id
                                          ) {
                                            form.setValue('default_status', '')
                                          }
                                        }}
                                        className="absolute -right-[10px] -top-[10px]"
                                      >
                                        <XCircle color="red" />
                                      </button>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )
                          }}
                          renderWhenFalse={() => {
                            return <></>
                          }}
                        />
                      ))}
                    </div>
                  )}
                </Droppable>
                <div className="mt-10 flex w-full justify-end">
                  <LoadingButton isLoading={isPending}>
                    <span>Salvar</span>
                  </LoadingButton>
                </div>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </DragDropContext>
  )
}
